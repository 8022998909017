import React, { useEffect, useState, Component, Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAttribute, newPositiveAttributeFilter, uriRef } from "@gooddata/sdk-model";
import { clearAllSelectedFilters, insightFiltersToApply } from "../../redux/actions/testActions";

import styles from "./Filter.module.scss";
import Arrow from "../../assets/Arrow.svg.js";
import { RootState } from "../../redux/store";

export interface IItem {
    title: string;
    uri: string;
}

interface IDropdownComponent {
    attributeId: IAttribute;
    title: string;
    item: IItem[];
    multiselect: boolean;
    setArray: Dispatch<SetStateAction<string[]>>;
}

export class AttributeFilterItem extends Component<IItem> {
    public onChange(uri: string) {
        return (event: React.ChangeEvent<HTMLInputElement>): void => {
            // eslint-disable-next-line no-console
            //console.log("TAG AttributeFilterItem onChange", uri, event.target.value === "on");

            if (event.target.value) {
                const test = newPositiveAttributeFilter(uriRef(uri), []);
                console.log("TAG 1 TEST: ", test);
                console.log("TESTAR igen snälla:; ", test);
                console.log("Event?: ", uri);
            }
        };
    }

    public render(): React.ReactNode {
        const { title, uri } = this.props;
        return (
            <label className="gd-list-item s-attribute-filter-list-item" style={{ display: "inline-flex" }}>
                <input type="checkbox" className="gd-input-checkbox" onChange={this.onChange(uri)} />
                <span>{title}</span>
            </label>
        );
    }
}

const DropdownComponent: React.FC<IDropdownComponent> = ({
    attributeId,
    title,
    item,
    multiselect,
    setArray,
}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectionTest, setSelectionTest] = useState<string[]>([]);
    const toggle = () => setOpen(!open);
    const clearFilters = useSelector((state: RootState) => state.testData.clearAllSelectedFilters);

    useEffect(() => {
        if (clearFilters) {
            setSelectionTest([]);
            dispatch(clearAllSelectedFilters(false));
        }
    }, [clearFilters, dispatch]);

    const buildAttributeFilterItem = (item: IItem): JSX.Element => {
        const { title } = item;

        return (
            <>
                <div className={styles.EachFilterDropdown}>
                    <p>{title}</p>
                    <button
                        className={styles.DropdownBtn}
                        style={{
                            backgroundColor: isItemInSelection(item.uri) ? "#ffaa01" : "white",
                            height: 10,
                            width: 10,
                            marginRight: 5,
                        }}
                        onClick={() => {
                            handleClick(item);
                        }}
                    >
                        {isItemInSelection(item.uri)}
                    </button>
                </div>
            </>
        );
    };

    useEffect(() => {
        if (selectionTest) {
            dispatch(insightFiltersToApply(attributeId, selectionTest));
            setArray(selectionTest);
        }
    }, [attributeId, dispatch, selectionTest, setArray]);

    function handleClick(i: IItem) {
        if (!selectionTest.some((current) => current === i.uri)) {
            if (!multiselect) {
                setSelectionTest([i.uri]);
            } else if (multiselect) {
                setSelectionTest([...selectionTest, i.uri]);
            }
        } else {
            let selectionAfterRemovalTEST = selectionTest;
            selectionAfterRemovalTEST = selectionAfterRemovalTEST.filter((current) => current !== i.uri);
            setSelectionTest([...selectionAfterRemovalTEST]);
        }
    }

    function isItemInSelection(item: any): boolean {
        if (selectionTest.find((current) => current === item)) {
            return true;
        }
        return false;
    }

    return (
        <div>
            <div tabIndex={0} onKeyUp={() => toggle()} onClick={() => toggle()}>
                <div className={styles.FilterCategories}>
                    <p>{title}</p>
                    {open ? (
                        <Arrow style={{ transform: "rotate(180deg)" }} color={"#ffaa01"} />
                    ) : (
                        <Arrow color={"white"} />
                    )}
                </div>
            </div>
            {open && (
                <>
                    <div>{item.map(buildAttributeFilterItem)}</div>
                </>
            )}
        </div>
    );
};

export default DropdownComponent;
