import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { AttributeElements } from "@gooddata/sdk-ui-filters";
import { attributeDisplayFormRef } from "@gooddata/sdk-model";
import styles from "./SurveyListStyle.module.scss";
import { Link } from "react-router-dom";

import * as Md from "../md/full";
import Header from "../components/Header/Header";
import SurveyListCard from "../components/SurveyListCard";
import {
    setSelectedCompareFilter,
    setSelectedYearFilter,
    setComparedSurveyName,
    selectedSurveyName,
} from "../redux/actions/testActions";
import { useDispatch } from "react-redux";

//Survey page not done implement when needed

interface ISurveyList {
    isComparison?: boolean;
}

const SurveyList: React.FC<ISurveyList> = ({ isComparison }) => {
    const userData = useSelector((state: RootState) => state.testData.currentUserSettings);
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const CLIENT_ID = userData
        ? userData.clientID.charAt(0).toUpperCase() + userData.clientID.substring(1)
        : "CLIENT ID NOT FOUND";

    const dispatch = useDispatch();
    console.log(" ", isBurgerOpen);
    console.log(" ", CLIENT_ID);

    return (
        <div className={isComparison ? styles.PageContentComparison : styles.SurveyPage}>
            <div className={styles.Header}>
                <Header
                    isTooSmall={false}
                    showSidebars={false}
                    callback={(value: boolean) => setIsBurgerOpen(value)}
                />
            </div>

            <div className={isComparison ? styles.PageContentComparison : styles.PageContent}>
                {!isComparison && <h1>YOUR REPORTS</h1>}
                <AttributeElements displayForm={attributeDisplayFormRef(Md.SurveyId.SurveyName)} limit={30}>
                    {({ validElements, error }) => {
                        if (error) {
                            return <div>{error}</div>;
                        }

                        return (
                            <div>
                                {validElements ? (
                                    validElements.items.map((item, index) => (
                                        <div key={index}>
                                            {isComparison ? (
                                                <SurveyListCard
                                                    onClick={() => {
                                                        dispatch(
                                                            setSelectedCompareFilter(
                                                                Md.SurveyId.SurveyName,
                                                                item.uri,
                                                            ),
                                                        );
                                                        dispatch(setComparedSurveyName(item.title));
                                                    }}
                                                    label={item.title}
                                                />
                                            ) : (
                                                <Link
                                                    to="/overview"
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <SurveyListCard
                                                        onClick={() => {
                                                            dispatch(
                                                                setSelectedYearFilter(
                                                                    Md.SurveyId.SurveyName,
                                                                    item.uri,
                                                                ),
                                                            );
                                                            dispatch(selectedSurveyName(item.title));
                                                        }}
                                                        label={item.title}
                                                    />
                                                </Link>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    }}
                </AttributeElements>
            </div>
        </div>
    );
};

export default SurveyList;
