import { idRef, IPositiveAttributeFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { InsightView } from "@gooddata/sdk-ui-ext";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { HeaderPredicates } from "@gooddata/sdk-ui";

import { RootState } from "../../redux/store";
import { IComparisonData } from "../utils/CmsValidators";
import styles from "./Form.module.scss";
import styless from "./Comparison.module.scss";
import CustomLoading from "../CustomLoading";
import * as Md from "../../md/full";

import btnStyle from "../../routes/SurveyListStyle.module.scss";
import { clearAllSelectedFilters } from "../../redux/actions/testActions";
import { getDeepDiveFilter } from "../utils/FilterValidators";
import { IHasNameUri } from "./OverviewForm";
import { CustomWidget } from "./DifferentiationForm";
import Page from "../Page";
import { APIError } from "graphql-hooks";

interface IComparedPage {
    sortedData: {}[];
    cmsData: IComparisonData;
    error: APIError<object> | undefined;
    loading: boolean;
    reduxFilter: any;
}

const positiveFilterInitialState = {
    filter: newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
};

export const ComparedPage: React.FC<IComparedPage> = ({
    sortedData,
    cmsData,
    error,
    loading,
    reduxFilter,
}) => {
    const dispatch = useDispatch();
    const filtersFromRedux = useSelector((state: RootState) => state.testData.insightFiltersToApply);
    const clearFilters = useSelector((state: RootState) => state.testData.clearAllSelectedFilters);
    const countryFilter = useSelector((state: RootState) => state.testData.countryFilter);
    const ageFilter = useSelector((state: RootState) => state.testData.ageFilter);
    const genderFilter = useSelector((state: RootState) => state.testData.genderFilter);
    const educationFilter = useSelector((state: RootState) => state.testData.educationFilter);
    const householdFilter = useSelector((state: RootState) => state.testData.householdFilter);
    const defaultDimensionsFilter = newPositiveAttributeFilter(Md.Dimension, ["differentiation"]);
    const [surveyIdFilter, setSurveyIdFilter] = useState<IPositiveAttributeFilter>(
        newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
    );
    const [dimensionScoreComparisonFilter, setDimensionScoreComparisonFilter] =
        useState<IPositiveAttributeFilter>(defaultDimensionsFilter);
    const [filters, setFilters] = useState<IPositiveAttributeFilter>(surveyIdFilter);
    const [tableFilters, setTableFilters] = useState<IPositiveAttributeFilter>(
        positiveFilterInitialState.filter,
    );
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const topTenAwarenessDrillableItems = [HeaderPredicates.identifierMatch("label.vanswers.valuetext")];

    const getDrillFilters = (
        state: IHasNameUri | null,
        awareness: IHasNameUri | null,
    ): IPositiveAttributeFilter => {
        let filter1 = newPositiveAttributeFilter(Md.ValueText, []);

        if (state) {
            filter1 = newPositiveAttributeFilter(Md.ValueText, { uris: [state.uri] });
        }

        if (awareness) {
            filter1 = newPositiveAttributeFilter(Md.ValueText, { uris: [awareness.uri] });
        }

        return filter1;
    };

    function onAwarenesslDrill(drillTarget: any) {
        const { name, uri } =
            drillTarget.drillContext.element === "bar"
                ? drillTarget.drillContext.intersection[1].header.attributeHeaderItem
                : drillTarget.drillContext[0].intersection[1].header.attributeHeaderItem;

        const newAwareness: IHasNameUri = {
            name,
            uri,
        };

        setTableFilters(getDrillFilters(null, newAwareness));
    }

    useEffect(() => {
        if (clearFilters) {
            setTableFilters(getDrillFilters(null, null));
            dispatch(clearAllSelectedFilters(false));
        }
    }, [clearFilters, dispatch]);

    useEffect(() => {
        if (reduxFilter && filtersFromRedux) {
            const newFilters = newPositiveAttributeFilter(filtersFromRedux.atrb, {
                uris: filtersFromRedux.ar,
            });
            const newFilter = newPositiveAttributeFilter(reduxFilter.atrb, {
                uris: [reduxFilter.ar],
            });
            setSurveyIdFilter(newFilter);
            setFilters(newFilters);
        }
    }, [reduxFilter, filtersFromRedux]);

    if (loading)
        return (
            <div className={styles.LoadingDiv}>
                <CustomLoading label="...Loading" />
            </div>
        );

    if (!cmsData && !sortedData)
        return (
            <Page>
                <p>Something went wrong! check your internet connection!</p>
            </Page>
        );

    if (error)
        return (
            <Page>
                <p>Error in loading data! try re freshing the page!</p>
            </Page>
        );

    return (
        <>
            <div className={styless.BigWidgetContainer}>
                {cmsData.section1.map((item, index) => (
                    <div key={index} className={styless.SmallWidgetContainerCompare}>
                        <p>{item.heading}</p>

                        <InsightView
                            insight={idRef(item.widgetId)}
                            filters={[
                                surveyIdFilter,
                                filters,
                                tableFilters,
                                countryFilter,
                                ageFilter,
                                genderFilter,
                                educationFilter,
                                householdFilter,
                            ]}
                        />
                    </div>
                ))}
            </div>

            <div className={styless.DeepDiveSelectContainer}>
                {cmsData.deepdiveComparison.map((item, index) => (
                    <div key={index} className={styless.SmallWidgetContainer}>
                        <p>{item.heading}</p>

                        <InsightView
                            insight={idRef(item.widgetId)}
                            filters={[
                                surveyIdFilter,
                                filters,
                                tableFilters,
                                countryFilter,
                                ageFilter,
                                genderFilter,
                                educationFilter,
                                householdFilter,
                            ]}
                        />
                        <div className={styless.ReportButtonContainer}>
                            <button
                                className={
                                    selectedIndex === index
                                        ? btnStyle.SelectedViewReportBtn
                                        : btnStyle.ViewReportBtn
                                }
                                style={{ width: "100%", height: "50%" }}
                                onClick={() => {
                                    setSelectedIndex(index);
                                    const deepDiveFilter = getDeepDiveFilter(item.heading);
                                    setDimensionScoreComparisonFilter(deepDiveFilter);
                                }}
                            >
                                SELECT
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className={styless.CustomWidgetContainer}>
                <p>{cmsData.deepdiveContent[0].heading}</p>
                <CustomWidget
                    widgetId={cmsData.deepdiveContent[0].widgetId}
                    filters={[
                        surveyIdFilter,
                        filters,
                        dimensionScoreComparisonFilter,
                        tableFilters,
                        countryFilter,
                        ageFilter,
                        genderFilter,
                        educationFilter,
                        householdFilter,
                    ]}
                    setFilters={setFilters}
                    setAnswerScale={(filter: any) =>
                        setDimensionScoreComparisonFilter(
                            newPositiveAttributeFilter("label.vanswers.answerscaledesc", {
                                uris: [filter.uri],
                            }),
                        )
                    }
                    answerScale={dimensionScoreComparisonFilter}
                />
            </div>

            {cmsData.demographics.map((item) =>
                item.widgets.map((widget, index) => (
                    <div key={index} className={styless.CustomWidgetContainer}>
                        <p>{widget.heading}</p>
                        <CustomWidget
                            widgetId={widget.widgetId}
                            filters={[
                                surveyIdFilter,
                                filters,
                                tableFilters,
                                countryFilter,
                                ageFilter,
                                genderFilter,
                                educationFilter,
                                householdFilter,
                            ]}
                            setFilters={setFilters}
                            setAnswerScale={(filter: any) =>
                                setDimensionScoreComparisonFilter(
                                    newPositiveAttributeFilter("label.vanswers.answerscaledesc", {
                                        uris: [filter.uri],
                                    }),
                                )
                            }
                            answerScale={dimensionScoreComparisonFilter}
                        />
                    </div>
                )),
            )}

            <div className={styless.KPIRowContainer}>
                {cmsData.kpis.map((item) =>
                    item.widgets.map((widget, index) => (
                        <div key={index} className={styless.SmallWidgetContainer}>
                            <p>{widget.heading}</p>

                            <InsightView
                                insight={idRef(widget.widgetId)}
                                filters={[
                                    surveyIdFilter,
                                    filters,
                                    tableFilters,
                                    countryFilter,
                                    ageFilter,
                                    genderFilter,
                                    educationFilter,
                                    householdFilter,
                                ]}
                            />
                        </div>
                    )),
                )}
            </div>

            <div className={styless.DeepDiveComparisonContainer}>
                {cmsData.top10Awareness.map((item) =>
                    item.widgets.map((widget, index) => (
                        <div key={index}>
                            <p>{widget.heading}</p>
                            <div style={{ height: 300, minWidth: 550 }}>
                                <InsightView
                                    insight={idRef(widget.widgetId)}
                                    filters={[
                                        surveyIdFilter,
                                        filters,
                                        tableFilters,
                                        countryFilter,
                                        ageFilter,
                                        genderFilter,
                                        educationFilter,
                                        householdFilter,
                                    ]}
                                    drillableItems={topTenAwarenessDrillableItems}
                                    onDrill={onAwarenesslDrill}
                                />
                            </div>
                        </div>
                    )),
                )}
            </div>
        </>
    );
};
