import React, { useState, useEffect, useCallback } from "react";
import throttle from "lodash/throttle";
import { Helmet } from "react-helmet";
import cx from "classnames";

import Header from "./Header/Header";
//import RouterBtns from "./controls/RouterBtns";

import styles from "./Page.module.scss";

interface IPageProps {
    className?: string;
    mainClassName?: string;
    title?: string;
    showSidebars?: boolean;
    showFilters?: boolean;
}

const Page: React.FC<IPageProps> = ({
    children,
    className = null,
    mainClassName = null,
    title = "Brandr Index",
    showSidebars = true,
    showFilters,
}) => {
    const [windowWidth, setWindowWidth] = useState(window ? window.innerWidth : null);
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);

    const isTooSmall = windowWidth && windowWidth < 666;
    //const isTooSmallFilter = windowWidth && windowWidth < 1150;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleResize = useCallback(
        throttle(() => {
            if (window) {
                setWindowWidth(window.innerWidth);
            }
        }, 200),
        [],
    );

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    // You need to set this manually to the smallest window width that can still fit all menu items

    return (
        <div
            className={
                isTooSmall
                    ? isTooSmall && isBurgerOpen
                        ? cx(styles.SidebarOnPage, className)
                        : cx(styles.Page, className)
                    : showFilters
                    ? cx(styles.SidebarOnPage, className)
                    : cx(styles.HideFilter, className)
            }
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <Header
                showSidebars={showSidebars}
                isTooSmall={isTooSmall}
                callback={(value: boolean) => setIsBurgerOpen(value)}
                showFilters={showFilters}
            />
            <main className={cx(styles.Main, mainClassName, "s-page")}>
                {/* <RouterBtns /> */}
                {children}
            </main>
        </div>
    );
};

export default Page;
