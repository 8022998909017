/* eslint-disable */
/* THIS FILE WAS AUTO-GENERATED USING CATALOG EXPORTER; YOU SHOULD NOT EDIT THIS FILE; GENERATE TIME: 2022-09-14T08:17:42.008Z; */
// @ts-ignore ignore unused imports here if they happen (e.g. when there is no measure in the workspace)
import {
    newAttribute,
    newMeasure,
    IAttribute,
    IMeasure,
    IMeasureDefinition,
    idRef,
} from "@gooddata/sdk-model";

/**
 * Attribute Title: Age
 * Attribute ID: attr.ageoptionset.respondentage
 */
export const Age = {
    /**
     * Display Form Title: Age interval
     * Display Form ID: label.ageoptionset.respondentage.ageinterval
     */
    Interval: newAttribute("label.ageoptionset.respondentage.ageinterval"),
    /**
     * Display Form Title: Age
     * Display Form ID: label.ageoptionset.respondentage
     */ Default: newAttribute("label.ageoptionset.respondentage"),
};
/**
 * Attribute Title: Answer label
 * Attribute ID: attr.vanswers.answerlabel
 */
export const AnswerLabel: IAttribute = newAttribute("label.vanswers.answerlabel");
/**
 * Attribute Title: Answer scale
 * Attribute ID: attr.vanswers.answerscaledesc
 */
export const AnswerScale: IAttribute = newAttribute("label.vanswers.answerscaledesc");
/**
 * Attribute Title: Answer Text
 * Attribute ID: attr.vanswers.answertext
 */
export const AnswerText: IAttribute = newAttribute("label.vanswers.answertext");
/**
 * Attribute Title: Benchmarking Country Code
 * Attribute ID: attr.vanswers_benchmarking.countrycode
 */
export const BenchmarkingCountryCode: IAttribute = newAttribute("label.vanswers_benchmarking.countrycode");
/**
 * Attribute Title: Benchmarking Survey id
 * Attribute ID: attr.vanswers_benchmarking.surveyid
 */
export const BenchmarkingSurveyId: IAttribute = newAttribute("label.vanswers_benchmarking.surveyid");
/**
 * Attribute Title: Country Code
 * Attribute ID: attr.vsurvey.countrycode
 */
export const CountryCode: IAttribute = newAttribute("label.vsurvey.countrycode");
/**
 * Attribute Title: Customer Description
 * Attribute ID: attr.vcustomers.customer_description
 */
export const CustomerDescription: IAttribute = newAttribute("label.vcustomers.customer_description");
/**
 * Attribute Title: Customer Id
 * Attribute ID: attr.vcustomers.customerid
 */
export const CustomerId: IAttribute = newAttribute("label.vcustomers.customerid");
/**
 * Attribute Title: Customer Name
 * Attribute ID: attr.vcustomers.customer_name
 */
export const CustomerName: IAttribute = newAttribute("label.vcustomers.customer_name");
/**
 * Attribute Title: Dimension
 * Attribute ID: attr.scoreformulation.score_name
 */
export const Dimension: IAttribute = newAttribute("label.scoreformulation.score_name");
/**
 * Attribute Title: Dimension Id
 * Attribute ID: attr.scoreformulation.score_id
 */
export const DimensionId: IAttribute = newAttribute("label.scoreformulation.score_id");
/**
 * Attribute Title: Education
 * Attribute ID: attr.educationoptionset.respondenteducation
 */
export const Education = {
    /**
     * Display Form Title: Education level
     * Display Form ID: label.educationoptionset.respondenteducation.educationlevel
     */
    Level: newAttribute("label.educationoptionset.respondenteducation.educationlevel"),
    /**
     * Display Form Title: Education
     * Display Form ID: label.educationoptionset.respondenteducation
     */ Default: newAttribute("label.educationoptionset.respondenteducation"),
};
/**
 * Attribute Title: Gender
 * Attribute ID: attr.genderoptionset.respondentgender
 */
export const Gender = {
    /**
     * Display Form Title: Gender
     * Display Form ID: label.genderoptionset.respondentgender.gender
     */
    Default: newAttribute("label.genderoptionset.respondentgender.gender"),
    /**
     * Display Form Title: Respondent gender
     * Display Form ID: label.genderoptionset.respondentgender
     */ RespondentGender: newAttribute("label.genderoptionset.respondentgender"),
};
/**
 * Attribute Title: Household income
 * Attribute ID: attr.incomeoptionset.respondentincome
 */
export const HouseholdIncome = {
    /**
     * Display Form Title: Income interval
     * Display Form ID: label.incomeoptionset.respondentincome.incomeinterval
     */
    IncomeInterval: newAttribute("label.incomeoptionset.respondentincome.incomeinterval"),
    /**
     * Display Form Title: Household income
     * Display Form ID: label.incomeoptionset.respondentincome
     */ Default: newAttribute("label.incomeoptionset.respondentincome"),
};
/**
 * Attribute Title: Location Region
 * Attribute ID: attr.vrespondents.locationregion
 */
export const LocationRegion: IAttribute = newAttribute("label.vrespondents.locationregion");
/**
 * Attribute Title: NPS Country Code
 * Attribute ID: attr.vnps_benchmarking.npscountrycode
 */
export const NPSCountryCode: IAttribute = newAttribute("label.vnps_benchmarking.npscountrycode");
/**
 * Attribute Title: NPS Record Key
 * Attribute ID: attr.vnps_benchmarking.npsrecordkey
 */
export const NPSRecordKey: IAttribute = newAttribute("label.vnps_benchmarking.npsrecordkey");
/**
 * Attribute Title: NPS Respondent Type
 * Attribute ID: attr.vnps_benchmarking.npsrespondenttype
 */
export const NPSRespondentType: IAttribute = newAttribute("label.vnps_benchmarking.npsrespondenttype");
/**
 * Attribute Title: NPS Survey Id
 * Attribute ID: attr.vnps_benchmarking.npssurveyid
 */
export const NPSSurveyId: IAttribute = newAttribute("label.vnps_benchmarking.npssurveyid");
/**
 * Attribute Title: Question Code
 * Attribute ID: attr.questions.question_code
 */
export const QuestionCode: IAttribute = newAttribute("label.questions.question_code");
/**
 * Attribute Title: Question Id
 * Attribute ID: attr.questions.question_id
 */
export const QuestionId: IAttribute = newAttribute("label.questions.question_id");
/**
 * Attribute Title: Question Long Description
 * Attribute ID: attr.questions.question_long_description
 */
export const QuestionLongDescription: IAttribute = newAttribute("label.questions.question_long_description");
/**
 * Attribute Title: Question Name
 * Attribute ID: attr.questions.question_name
 */
export const QuestionName: IAttribute = newAttribute("label.questions.question_name");
/**
 * Attribute Title: Question Short Description
 * Attribute ID: attr.questions.question_short_description
 */
export const QuestionShortDescription: IAttribute = newAttribute(
    "label.questions.question_short_description",
);
/**
 * Attribute Title: Record Key
 * Attribute ID: attr.vanswers_benchmarking.recordkey
 */
export const RecordKey: IAttribute = newAttribute("label.vanswers_benchmarking.recordkey");
/**
 * Attribute Title: Respondent Id
 * Attribute ID: attr.vrespondents.respondentid
 */
export const RespondentId: IAttribute = newAttribute("label.vrespondents.respondentid");
/**
 * Attribute Title: Respondent type
 * Attribute ID: attr.vanswers_benchmarking.respondenttype
 */
export const RespondentType: IAttribute = newAttribute("label.vanswers_benchmarking.respondenttype");
/**
 * Attribute Title: Response Status
 * Attribute ID: attr.vrespondents.responsestatus
 */
export const ResponseStatus: IAttribute = newAttribute("label.vrespondents.responsestatus");
/**
 * Attribute Title: Survey Id
 * Attribute ID: attr.vsurvey.surveyid
 */
export const SurveyId = {
    /**
     * Display Form Title: Survey name
     * Display Form ID: label.vsurvey.surveyid.surveyname
     */
    SurveyName: newAttribute("label.vsurvey.surveyid.surveyname"),
    /**
     * Display Form Title: Survey Id
     * Display Form ID: label.vsurvey.surveyid
     */ Default: newAttribute("label.vsurvey.surveyid"),
};
/**
 * Attribute Title: Value Text
 * Attribute ID: attr.vanswers.valuetext
 */
export const ValueText: IAttribute = newAttribute("label.vanswers.valuetext");
/**
 * Attribute Title: Year
 * Attribute ID: attr.vsurvey.year
 */
export const Year: IAttribute = newAttribute("label.vsurvey.year");
/**
 * Metric Title: % of survey respondents
 * Metric ID: aeXO7dr3nFM6
 * Metric Type: MAQL Metric
 */
export const PercentOfSurveyRespondents: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aeXO7dr3nFM6", "measure"),
);
/**
 * Metric Title: Average response for score questions (benchmarking)
 * Metric ID: aafve3my7SAo
 * Metric Type: MAQL Metric
 */
export const AverageResponseForScoreQuestionsBenchmarking: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aafve3my7SAo", "measure"),
);
/**
 * Metric Title: Average response for score questions (survey)
 * Metric ID: aanWbPB0seOW
 * Metric Type: MAQL Metric
 */
export const AverageResponseForScoreQuestionsSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aanWbPB0seOW", "measure"),
);
/**
 * Metric Title: Average score per question(survey)
 * Metric ID: aadsJ1vRkJLG
 * Metric Type: MAQL Metric
 */
export const AverageScorePerQuestionSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aadsJ1vRkJLG", "measure"),
);
/**
 * Metric Title: Benchmarking Brandr score
 * Metric ID: aarb4jzKMLfc
 * Metric Type: MAQL Metric
 */
export const BenchmarkingBrandrScore: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aarb4jzKMLfc", "measure"),
);
/**
 * Metric Title: Benchmarking Country score
 * Metric ID: aaicjivZMofe
 * Metric Type: MAQL Metric
 */
export const BenchmarkingCountryScore: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaicjivZMofe", "measure"),
);
/**
 * Metric Title: Benchmarking dimension Highest 5% survey
 * Metric ID: aacGJuEZJ4Uf
 * Metric Type: MAQL Metric
 */
export const BenchmarkingDimensionHighest5PercentSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aacGJuEZJ4Uf", "measure"),
);
/**
 * Metric Title: Benchmarking dimension Lowest 5% survey
 * Metric ID: aabGMwCdLRgO
 * Metric Type: MAQL Metric
 */
export const BenchmarkingDimensionLowest5PercentSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabGMwCdLRgO", "measure"),
);
/**
 * Metric Title: Benchmarking dimension score
 * Metric ID: aahciYzu4ZrN
 * Metric Type: MAQL Metric
 */
export const BenchmarkingDimensionScore: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aahciYzu4ZrN", "measure"),
);
/**
 * Metric Title: Benchmarking dimension score by country
 * Metric ID: aab86N7UJ8cO
 * Metric Type: MAQL Metric
 */
export const BenchmarkingDimensionScoreByCountry: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aab86N7UJ8cO", "measure"),
);
/**
 * Metric Title: Benchmarking dimension score by survey
 * Metric ID: aabGLVsJ3XUM
 * Metric Type: MAQL Metric
 */
export const BenchmarkingDimensionScoreBySurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabGLVsJ3XUM", "measure"),
);
/**
 * Metric Title: Benchmarking Highest 5% survey
 * Metric ID: aabcvOsC9UJd
 * Metric Type: MAQL Metric
 */
export const BenchmarkingHighest5PercentSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabcvOsC9UJd", "measure"),
);
/**
 * Metric Title: Benchmarking Lowest 5% survey
 * Metric ID: aacGINjB5HmV
 * Metric Type: MAQL Metric
 */
export const BenchmarkingLowest5PercentSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aacGINjB5HmV", "measure"),
);
/**
 * Metric Title: Benchmarking Loyalty Score
 * Metric ID: aaiczIKYbjTT
 * Metric Type: MAQL Metric
 */
export const BenchmarkingLoyaltyScore: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaiczIKYbjTT", "measure"),
);
/**
 * Metric Title: Benchmarking NPS calc
 * Metric ID: aahcv4f6ZMaF
 * Metric Type: MAQL Metric
 */
export const BenchmarkingNPSCalc: IMeasure<IMeasureDefinition> = newMeasure(idRef("aahcv4f6ZMaF", "measure"));
/**
 * Metric Title: Benchmarking NPS Score
 * Metric ID: aaccF7AHZ4rx
 * Metric Type: MAQL Metric
 */
export const BenchmarkingNPSScore: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaccF7AHZ4rx", "measure"),
);
/**
 * Metric Title: Benchmarking question score Highest 5% by survey
 * Metric ID: aabwpSmgUGjb
 * Metric Type: MAQL Metric
 */
export const BenchmarkingQuestionScoreHighest5PercentBySurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabwpSmgUGjb", "measure"),
);
/**
 * Metric Title: Benchmarking question score Lowest 5% by survey
 * Metric ID: aacwpSmgUGjb
 * Metric Type: MAQL Metric
 */
export const BenchmarkingQuestionScoreLowest5PercentBySurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aacwpSmgUGjb", "measure"),
);
/**
 * Metric Title: Benchmarking score calc - step 1
 * Metric ID: aawb90rtNmZd
 * Metric Type: MAQL Metric
 */
export const BenchmarkingScoreCalcStep1: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aawb90rtNmZd", "measure"),
);
/**
 * Metric Title: Benchmarking score calc - step 2
 * Metric ID: aawb6E0qNmPe
 * Metric Type: MAQL Metric
 */
export const BenchmarkingScoreCalcStep2: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aawb6E0qNmPe", "measure"),
);
/**
 * Metric Title: Benchmarking score calc - step 3
 * Metric ID: aalb6OK1Mb8E
 * Metric Type: MAQL Metric
 */
export const BenchmarkingScoreCalcStep3: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aalb6OK1Mb8E", "measure"),
);
/**
 * Metric Title: Benchmarking score calc - step 4
 * Metric ID: aafcbibbMlYN
 * Metric Type: MAQL Metric
 */
export const BenchmarkingScoreCalcStep4: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aafcbibbMlYN", "measure"),
);
/**
 * Metric Title: Benchmarking score calc - step 5
 * Metric ID: aagceHMSY5cK
 * Metric Type: MAQL Metric
 */
export const BenchmarkingScoreCalcStep5: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aagceHMSY5cK", "measure"),
);
/**
 * Metric Title: Benchmarking score calc - step 6
 * Metric ID: aahceHMSY5cK
 * Metric Type: MAQL Metric
 */
export const BenchmarkingScoreCalcStep6: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aahceHMSY5cK", "measure"),
);
/**
 * Metric Title: Benchmarking Survey score
 * Metric ID: aacGIpyE4WdC
 * Metric Type: MAQL Metric
 */
export const BenchmarkingSurveyScore: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aacGIpyE4WdC", "measure"),
);
/**
 * Metric Title: Benchmarking Trust Score
 * Metric ID: aaocseav8pWx
 * Metric Type: MAQL Metric
 */
export const BenchmarkingTrustScore: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaocseav8pWx", "measure"),
);
/**
 * Metric Title: Detractors (benchmarking)
 * Metric ID: aacc1cmcjker
 * Metric Type: MAQL Metric
 */
export const DetractorsBenchmarking: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aacc1cmcjker", "measure"),
);
/**
 * Metric Title: Detractors (survey)
 * Metric ID: aagg72xuu7vL
 * Metric Type: MAQL Metric
 */
export const DetractorsSurvey: IMeasure<IMeasureDefinition> = newMeasure(idRef("aagg72xuu7vL", "measure"));
/**
 * Metric Title: Dimension score for deep dives (benchmarking)
 * Metric ID: aadvPt0KJY0v
 * Metric Type: MAQL Metric
 */
export const DimensionScoreForDeepDivesBenchmarking: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aadvPt0KJY0v", "measure"),
);
/**
 * Metric Title: Dimension score for deep dives (survey)
 * Metric ID: aabtGseUpnsV
 * Metric Type: MAQL Metric
 */
export const DimensionScoreForDeepDivesSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabtGseUpnsV", "measure"),
);
/**
 * Metric Title: Dimension score highest 5% for deep dives by survey (benchmarking)
 * Metric ID: aabwq1ihUEE2
 * Metric Type: MAQL Metric
 */
export const DimensionScoreHighest5PercentForDeepDivesBySurveyBenchmarking: IMeasure<IMeasureDefinition> =
    newMeasure(idRef("aabwq1ihUEE2", "measure"));
/**
 * Metric Title: Dimension score lowest 5% for deep dives by survey (benchmarking)
 * Metric ID: aabwrwk0UEuF
 * Metric Type: MAQL Metric
 */
export const DimensionScoreLowest5PercentForDeepDivesBySurveyBenchmarking: IMeasure<IMeasureDefinition> =
    newMeasure(idRef("aabwrwk0UEuF", "measure"));
/**
 * Metric Title: Gantt start (custom widget)
 * Metric ID: aaqQv2Cd8usP
 * Metric Type: MAQL Metric
 */
export const GanttStartCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaqQv2Cd8usP", "measure"),
);
/**
 * Metric Title: Neutrals (survey)
 * Metric ID: aadhh3mfuhrH
 * Metric Type: MAQL Metric
 */
export const NeutralsSurvey: IMeasure<IMeasureDefinition> = newMeasure(idRef("aadhh3mfuhrH", "measure"));
/**
 * Metric Title: Number of half the neutral responses for score questions by all answer scale(custom widget)
 * Metric ID: aauQ9v22VoqG
 * Metric Type: MAQL Metric
 */
export const NumberOfHalfTheNeutralResponsesForScoreQuestionsByAllAnswerScaleCustomWidget: IMeasure<IMeasureDefinition> =
    newMeasure(idRef("aauQ9v22VoqG", "measure"));
/**
 * Metric Title: Number of negative responses for score questions by all answer scale and labels (custom widget)
 * Metric ID: aaIQl36FU9vw
 * Metric Type: MAQL Metric
 */
export const NumberOfNegativeResponsesForScoreQuestionsByAllAnswerScaleAndLabelsCustomWidget: IMeasure<IMeasureDefinition> =
    newMeasure(idRef("aaIQl36FU9vw", "measure"));
/**
 * Metric Title: Number of respondents for score question per answer scale
 * Metric ID: aabeaxXTUrch
 * Metric Type: MAQL Metric
 */
export const NumberOfRespondentsForScoreQuestionPerAnswerScale: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabeaxXTUrch", "measure"),
);
/**
 * Metric Title: Number of responses for score questions (survey)
 * Metric ID: aaZKgMOFRlIa
 * Metric Type: MAQL Metric
 */
export const NumberOfResponsesForScoreQuestionsSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaZKgMOFRlIa", "measure"),
);
/**
 * Metric Title: Number of responses score questions and age all in all other
 * Metric ID: aae7n7Ponnuc
 * Metric Type: MAQL Metric
 */
export const NumberOfResponsesScoreQuestionsAndAgeAllInAllOther: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aae7n7Ponnuc", "measure"),
);
/**
 * Metric Title: Number of responses score questions and education all in all other
 * Metric ID: aac7z41mxUcz
 * Metric Type: MAQL Metric
 */
export const NumberOfResponsesScoreQuestionsAndEducationAllInAllOther: IMeasure<IMeasureDefinition> =
    newMeasure(idRef("aac7z41mxUcz", "measure"));
/**
 * Metric Title: Number of responses score questions and gender all in all other
 * Metric ID: aajLdGgZpgIo
 * Metric Type: MAQL Metric
 */
export const NumberOfResponsesScoreQuestionsAndGenderAllInAllOther: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aajLdGgZpgIo", "measure"),
);
/**
 * Metric Title: Number of responses score questions and income all in all other
 * Metric ID: aao7xLgBmSXI
 * Metric Type: MAQL Metric
 */
export const NumberOfResponsesScoreQuestionsAndIncomeAllInAllOther: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aao7xLgBmSXI", "measure"),
);
/**
 * Metric Title: Percent of total sizing age(custom widget)
 * Metric ID: aag7s6svjCxk
 * Metric Type: MAQL Metric
 */
export const PercentOfTotalSizingAgeCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aag7s6svjCxk", "measure"),
);
/**
 * Metric Title: Percent of total sizing edu(custom widget)
 * Metric ID: aad7uH3qxRmP
 * Metric Type: MAQL Metric
 */
export const PercentOfTotalSizingEduCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aad7uH3qxRmP", "measure"),
);
/**
 * Metric Title: Percent of total sizing gender(custom widget)
 * Metric ID: aab7t8S1xTCY
 * Metric Type: MAQL Metric
 */
export const PercentOfTotalSizingGenderCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aab7t8S1xTCY", "measure"),
);
/**
 * Metric Title: Percent of total sizing income(custom widget)
 * Metric ID: aaq7uWwjmS26
 * Metric Type: MAQL Metric
 */
export const PercentOfTotalSizingIncomeCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaq7uWwjmS26", "measure"),
);
/**
 * Metric Title: Percent of total sizing question list(custom widget)
 * Metric ID: aageuNhKeqB2
 * Metric Type: MAQL Metric
 */
export const PercentOfTotalSizingQuestionListCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aageuNhKeqB2", "measure"),
);
/**
 * Metric Title: Promoters (benchmarking)
 * Metric ID: aadc1fKFvyOZ
 * Metric Type: MAQL Metric
 */
export const PromotersBenchmarking: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aadc1fKFvyOZ", "measure"),
);
/**
 * Metric Title: Promoters (survey)
 * Metric ID: aaeg7bw6HwBx
 * Metric Type: MAQL Metric
 */
export const PromotersSurvey: IMeasure<IMeasureDefinition> = newMeasure(idRef("aaeg7bw6HwBx", "measure"));
/**
 * Metric Title: Question is score questions (survey)
 * Metric ID: aaAKM7vX3nTh
 * Metric Type: MAQL Metric
 */
export const QuestionIsScoreQuestionsSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaAKM7vX3nTh", "measure"),
);
/**
 * Metric Title: Question score by age
 * Metric ID: aabD20BtqtGl
 * Metric Type: MAQL Metric
 */
export const QuestionScoreByAge: IMeasure<IMeasureDefinition> = newMeasure(idRef("aabD20BtqtGl", "measure"));
/**
 * Metric Title: Question score by education
 * Metric ID: aabD24kCHavW
 * Metric Type: MAQL Metric
 */
export const QuestionScoreByEducation: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabD24kCHavW", "measure"),
);
/**
 * Metric Title: Question score by gender
 * Metric ID: aacD2bt9CgT4
 * Metric Type: MAQL Metric
 */
export const QuestionScoreByGender: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aacD2bt9CgT4", "measure"),
);
/**
 * Metric Title: Question score by income
 * Metric ID: aabD3bQIJqYk
 * Metric Type: MAQL Metric
 */
export const QuestionScoreByIncome: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabD3bQIJqYk", "measure"),
);
/**
 * Metric Title: Question score for deep dive (benchmarking)
 * Metric ID: aahveivTaX8j
 * Metric Type: MAQL Metric
 */
export const QuestionScoreForDeepDiveBenchmarking: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aahveivTaX8j", "measure"),
);
/**
 * Metric Title: Question score for deep dive (survey)
 * Metric ID: aabtGOYVnZRR
 * Metric Type: MAQL Metric
 */
export const QuestionScoreForDeepDiveSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabtGOYVnZRR", "measure"),
);
/**
 * Metric Title: Question score for deep dive by survey (benchmarking)
 * Metric ID: aabwqqklUDTU
 * Metric Type: MAQL Metric
 */
export const QuestionScoreForDeepDiveBySurveyBenchmarking: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aabwqqklUDTU", "measure"),
);
/**
 * Metric Title: Respondent has answered
 * Metric ID: aao9gNtDzvJ2
 * Metric Type: MAQL Metric
 */
export const RespondentHasAnswered: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aao9gNtDzvJ2", "measure"),
);
/**
 * Metric Title: Respondent type is detractor (benchmarking)
 * Metric ID: aaecEzZ0ZLGi
 * Metric Type: MAQL Metric
 */
export const RespondentTypeIsDetractorBenchmarking: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaecEzZ0ZLGi", "measure"),
);
/**
 * Metric Title: Respondent type is promoter (benchmarking)
 * Metric ID: aamcsaLYYedF
 * Metric Type: MAQL Metric
 */
export const RespondentTypeIsPromoterBenchmarking: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aamcsaLYYedF", "measure"),
);
/**
 * Metric Title: Response is detractor (survey)
 * Metric ID: aag9isPUxJVp
 * Metric Type: MAQL Metric
 */
export const ResponseIsDetractorSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aag9isPUxJVp", "measure"),
);
/**
 * Metric Title: Response is negative (custom widget)
 * Metric ID: aaDQiGZAqiwp
 * Metric Type: MAQL Metric
 */
export const ResponseIsNegativeCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaDQiGZAqiwp", "measure"),
);
/**
 * Metric Title: Response is neutral (custom widget)
 * Metric ID: aasQ7SCAlmto
 * Metric Type: MAQL Metric
 */
export const ResponseIsNeutralCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aasQ7SCAlmto", "measure"),
);
/**
 * Metric Title: Response is promoter (survey)
 * Metric ID: aaf9isPUxJVp
 * Metric Type: MAQL Metric
 */
export const ResponseIsPromoterSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaf9isPUxJVp", "measure"),
);
/**
 * Metric Title: Responses for score questions by all scales labels
 * Metric ID: abheln2wa7vC
 * Metric Type: MAQL Metric
 */
export const ResponsesForScoreQuestionsByAllScalesLabels: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("abheln2wa7vC", "measure"),
);
/**
 * Metric Title: Responses for score questions by question id, all scales and labels
 * Metric ID: aagLaGSLJsT4
 * Metric Type: MAQL Metric
 */
export const ResponsesForScoreQuestionsByQuestionIdAllScalesAndLabels: IMeasure<IMeasureDefinition> =
    newMeasure(idRef("aagLaGSLJsT4", "measure"));
/**
 * Metric Title: Responses for score questions by scales and all labels
 * Metric ID: aauRFDzWIjJf
 * Metric Type: MAQL Metric
 */
export const ResponsesForScoreQuestionsByScalesAndAllLabels: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aauRFDzWIjJf", "measure"),
);
/**
 * Metric Title: Survey Dimension score
 * Metric ID: aak9irTHFTkE
 * Metric Type: MAQL Metric
 */
export const SurveyDimensionScore: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aak9irTHFTkE", "measure"),
);
/**
 * Metric Title: Survey Loyalty Score
 * Metric ID: aaf9VufHUW6b
 * Metric Type: MAQL Metric
 */
export const SurveyLoyaltyScore: IMeasure<IMeasureDefinition> = newMeasure(idRef("aaf9VufHUW6b", "measure"));
/**
 * Metric Title: Survey NPS calc
 * Metric ID: aad9h92jxLXR
 * Metric Type: MAQL Metric
 */
export const SurveyNPSCalc: IMeasure<IMeasureDefinition> = newMeasure(idRef("aad9h92jxLXR", "measure"));
/**
 * Metric Title: Survey NPS Score
 * Metric ID: aaj9S8jKVwNk
 * Metric Type: MAQL Metric
 */
export const SurveyNPSScore: IMeasure<IMeasureDefinition> = newMeasure(idRef("aaj9S8jKVwNk", "measure"));
/**
 * Metric Title: Survey score calc - step 1
 * Metric ID: aag4sK7sLQkT
 * Metric Type: MAQL Metric
 */
export const SurveyScoreCalcStep1: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aag4sK7sLQkT", "measure"),
);
/**
 * Metric Title: Survey score calc - step 2
 * Metric ID: aad4A1rlCLOK
 * Metric Type: MAQL Metric
 */
export const SurveyScoreCalcStep2: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aad4A1rlCLOK", "measure"),
);
/**
 * Metric Title: Survey score calc - step 3
 * Metric ID: aad4sd6vMUb8
 * Metric Type: MAQL Metric
 */
export const SurveyScoreCalcStep3: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aad4sd6vMUb8", "measure"),
);
/**
 * Metric Title: Survey score calc - step 4
 * Metric ID: aab4D1JqRz4x
 * Metric Type: MAQL Metric
 */
export const SurveyScoreCalcStep4: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aab4D1JqRz4x", "measure"),
);
/**
 * Metric Title: Survey score calc - step 5
 * Metric ID: aaf4C6YlMUAd
 * Metric Type: MAQL Metric
 */
export const SurveyScoreCalcStep5: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaf4C6YlMUAd", "measure"),
);
/**
 * Metric Title: Survey score calc - step 6
 * Metric ID: aab4EAGnyeC9
 * Metric Type: MAQL Metric
 */
export const SurveyScoreCalcStep6: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aab4EAGnyeC9", "measure"),
);
/**
 * Metric Title: Survey Trust Score
 * Metric ID: aaq9QKyfTmTH
 * Metric Type: MAQL Metric
 */
export const SurveyTrustScore: IMeasure<IMeasureDefinition> = newMeasure(idRef("aaq9QKyfTmTH", "measure"));
/**
 * Metric Title: Top 10 responses
 * Metric ID: aaeaQ3Q8iFo7
 * Metric Type: MAQL Metric
 */
export const Top10Responses: IMeasure<IMeasureDefinition> = newMeasure(idRef("aaeaQ3Q8iFo7", "measure"));
/**
 * Metric Title: Total negative responses and half neutral (custom widget)
 * Metric ID: aavQUxsmQJzf
 * Metric Type: MAQL Metric
 */
export const TotalNegativeResponsesAndHalfNeutralCustomWidget: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aavQUxsmQJzf", "measure"),
);
/**
 * Metric Title: Total nr respondents (survey)
 * Metric ID: aad9YCveLcHc
 * Metric Type: MAQL Metric
 */
export const TotalNrRespondentsSurvey: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aad9YCveLcHc", "measure"),
);
/**
 * Metric Title: Total nr respondents (survey) BY ALL D WITHOUT PF
 * Metric ID: aaesJSJmkJm6
 * Metric Type: MAQL Metric
 */
export const TotalNrRespondentsSurveyBYALLDWITHOUTPF: IMeasure<IMeasureDefinition> = newMeasure(
    idRef("aaesJSJmkJm6", "measure"),
);
/**
 * Fact Title: Dimension Weight
 * Fact ID: fact.scoreformulation.score_weight
 */
export const DimensionWeight = {
    /**
     * Fact Title: Dimension Weight
     * Fact ID: fact.scoreformulation.score_weight
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.scoreformulation.score_weight", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Dimension Weight
     * Fact ID: fact.scoreformulation.score_weight
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.scoreformulation.score_weight", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Dimension Weight
     * Fact ID: fact.scoreformulation.score_weight
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.scoreformulation.score_weight", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Dimension Weight
     * Fact ID: fact.scoreformulation.score_weight
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.scoreformulation.score_weight", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Dimension Weight
     * Fact ID: fact.scoreformulation.score_weight
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.scoreformulation.score_weight", "fact"), (m) =>
        m.aggregation("median"),
    ),
    /**
     * Fact Title: Dimension Weight
     * Fact ID: fact.scoreformulation.score_weight
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.scoreformulation.score_weight", "fact"), (m) =>
        m.aggregation("runsum"),
    ),
};
/**
 * Fact Title: Extraq 1
 * Fact ID: fact.vrespondents.extraq1
 */
export const Extraq1 = {
    /**
     * Fact Title: Extraq 1
     * Fact ID: fact.vrespondents.extraq1
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.vrespondents.extraq1", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Extraq 1
     * Fact ID: fact.vrespondents.extraq1
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.vrespondents.extraq1", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Extraq 1
     * Fact ID: fact.vrespondents.extraq1
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.vrespondents.extraq1", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Extraq 1
     * Fact ID: fact.vrespondents.extraq1
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.vrespondents.extraq1", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Extraq 1
     * Fact ID: fact.vrespondents.extraq1
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.vrespondents.extraq1", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Extraq 1
     * Fact ID: fact.vrespondents.extraq1
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.vrespondents.extraq1", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Extraq 2
 * Fact ID: fact.vrespondents.extraq2
 */
export const Extraq2 = {
    /**
     * Fact Title: Extraq 2
     * Fact ID: fact.vrespondents.extraq2
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.vrespondents.extraq2", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Extraq 2
     * Fact ID: fact.vrespondents.extraq2
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.vrespondents.extraq2", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Extraq 2
     * Fact ID: fact.vrespondents.extraq2
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.vrespondents.extraq2", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Extraq 2
     * Fact ID: fact.vrespondents.extraq2
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.vrespondents.extraq2", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Extraq 2
     * Fact ID: fact.vrespondents.extraq2
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.vrespondents.extraq2", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Extraq 2
     * Fact ID: fact.vrespondents.extraq2
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.vrespondents.extraq2", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: NPS Respondents
 * Fact ID: fact.vnps_benchmarking.npsrespondents
 */
export const NPSRespondents = {
    /**
     * Fact Title: NPS Respondents
     * Fact ID: fact.vnps_benchmarking.npsrespondents
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.vnps_benchmarking.npsrespondents", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: NPS Respondents
     * Fact ID: fact.vnps_benchmarking.npsrespondents
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.vnps_benchmarking.npsrespondents", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: NPS Respondents
     * Fact ID: fact.vnps_benchmarking.npsrespondents
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.vnps_benchmarking.npsrespondents", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: NPS Respondents
     * Fact ID: fact.vnps_benchmarking.npsrespondents
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.vnps_benchmarking.npsrespondents", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: NPS Respondents
     * Fact ID: fact.vnps_benchmarking.npsrespondents
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.vnps_benchmarking.npsrespondents", "fact"), (m) =>
        m.aggregation("median"),
    ),
    /**
     * Fact Title: NPS Respondents
     * Fact ID: fact.vnps_benchmarking.npsrespondents
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.vnps_benchmarking.npsrespondents", "fact"), (m) =>
        m.aggregation("runsum"),
    ),
};
/**
 * Fact Title: NPS Response
 * Fact ID: fact.vnps_benchmarking.npsresponse
 */
export const NPSResponse = {
    /**
     * Fact Title: NPS Response
     * Fact ID: fact.vnps_benchmarking.npsresponse
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.vnps_benchmarking.npsresponse", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: NPS Response
     * Fact ID: fact.vnps_benchmarking.npsresponse
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.vnps_benchmarking.npsresponse", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: NPS Response
     * Fact ID: fact.vnps_benchmarking.npsresponse
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.vnps_benchmarking.npsresponse", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: NPS Response
     * Fact ID: fact.vnps_benchmarking.npsresponse
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.vnps_benchmarking.npsresponse", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: NPS Response
     * Fact ID: fact.vnps_benchmarking.npsresponse
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.vnps_benchmarking.npsresponse", "fact"), (m) =>
        m.aggregation("median"),
    ),
    /**
     * Fact Title: NPS Response
     * Fact ID: fact.vnps_benchmarking.npsresponse
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.vnps_benchmarking.npsresponse", "fact"), (m) =>
        m.aggregation("runsum"),
    ),
};
/**
 * Fact Title: Question Weight
 * Fact ID: fact.questions.question_weight
 */
export const QuestionWeight = {
    /**
     * Fact Title: Question Weight
     * Fact ID: fact.questions.question_weight
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.questions.question_weight", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Question Weight
     * Fact ID: fact.questions.question_weight
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.questions.question_weight", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Question Weight
     * Fact ID: fact.questions.question_weight
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.questions.question_weight", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Question Weight
     * Fact ID: fact.questions.question_weight
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.questions.question_weight", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Question Weight
     * Fact ID: fact.questions.question_weight
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.questions.question_weight", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Question Weight
     * Fact ID: fact.questions.question_weight
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.questions.question_weight", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Response
 * Fact ID: fact.vanswers.response
 */
export const Response = {
    /**
     * Fact Title: Response
     * Fact ID: fact.vanswers.response
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.vanswers.response", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Response
     * Fact ID: fact.vanswers.response
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.vanswers.response", "fact"), (m) => m.aggregation("avg")),
    /**
     * Fact Title: Response
     * Fact ID: fact.vanswers.response
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.vanswers.response", "fact"), (m) => m.aggregation("min")),
    /**
     * Fact Title: Response
     * Fact ID: fact.vanswers.response
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.vanswers.response", "fact"), (m) => m.aggregation("max")),
    /**
     * Fact Title: Response
     * Fact ID: fact.vanswers.response
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.vanswers.response", "fact"), (m) => m.aggregation("median")),
    /**
     * Fact Title: Response
     * Fact ID: fact.vanswers.response
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.vanswers.response", "fact"), (m) => m.aggregation("runsum")),
};
/**
 * Fact Title: Total Respondents
 * Fact ID: fact.vanswers_benchmarking.total_respondents
 */
export const TotalRespondents = {
    /**
     * Fact Title: Total Respondents
     * Fact ID: fact.vanswers_benchmarking.total_respondents
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.vanswers_benchmarking.total_respondents", "fact"), (m) =>
        m.aggregation("sum"),
    ),
    /**
     * Fact Title: Total Respondents
     * Fact ID: fact.vanswers_benchmarking.total_respondents
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.vanswers_benchmarking.total_respondents", "fact"), (m) =>
        m.aggregation("avg"),
    ),
    /**
     * Fact Title: Total Respondents
     * Fact ID: fact.vanswers_benchmarking.total_respondents
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.vanswers_benchmarking.total_respondents", "fact"), (m) =>
        m.aggregation("min"),
    ),
    /**
     * Fact Title: Total Respondents
     * Fact ID: fact.vanswers_benchmarking.total_respondents
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.vanswers_benchmarking.total_respondents", "fact"), (m) =>
        m.aggregation("max"),
    ),
    /**
     * Fact Title: Total Respondents
     * Fact ID: fact.vanswers_benchmarking.total_respondents
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.vanswers_benchmarking.total_respondents", "fact"), (m) =>
        m.aggregation("median"),
    ),
    /**
     * Fact Title: Total Respondents
     * Fact ID: fact.vanswers_benchmarking.total_respondents
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.vanswers_benchmarking.total_respondents", "fact"), (m) =>
        m.aggregation("runsum"),
    ),
};
/**
 * Fact Title: Total Response
 * Fact ID: fact.vanswers_benchmarking.total_response
 */
export const TotalResponse = {
    /**
     * Fact Title: Total Response
     * Fact ID: fact.vanswers_benchmarking.total_response
     * Fact Aggregation: sum
     */
    Sum: newMeasure(idRef("fact.vanswers_benchmarking.total_response", "fact"), (m) => m.aggregation("sum")),
    /**
     * Fact Title: Total Response
     * Fact ID: fact.vanswers_benchmarking.total_response
     * Fact Aggregation: avg
     */ Avg: newMeasure(idRef("fact.vanswers_benchmarking.total_response", "fact"), (m) =>
        m.aggregation("avg"),
    ),
    /**
     * Fact Title: Total Response
     * Fact ID: fact.vanswers_benchmarking.total_response
     * Fact Aggregation: min
     */ Min: newMeasure(idRef("fact.vanswers_benchmarking.total_response", "fact"), (m) =>
        m.aggregation("min"),
    ),
    /**
     * Fact Title: Total Response
     * Fact ID: fact.vanswers_benchmarking.total_response
     * Fact Aggregation: max
     */ Max: newMeasure(idRef("fact.vanswers_benchmarking.total_response", "fact"), (m) =>
        m.aggregation("max"),
    ),
    /**
     * Fact Title: Total Response
     * Fact ID: fact.vanswers_benchmarking.total_response
     * Fact Aggregation: median
     */ Median: newMeasure(idRef("fact.vanswers_benchmarking.total_response", "fact"), (m) =>
        m.aggregation("median"),
    ),
    /**
     * Fact Title: Total Response
     * Fact ID: fact.vanswers_benchmarking.total_response
     * Fact Aggregation: runsum
     */ Runsum: newMeasure(idRef("fact.vanswers_benchmarking.total_response", "fact"), (m) =>
        m.aggregation("runsum"),
    ),
};
/** Available Date Data Sets */
export const DateDatasets = {};
export const Insights = {
    /**
     * Insight Title: Gender
     * Insight ID: aagPafGjn280
     */
    Gender_1: "aagPafGjn280",
    /**
     * Insight Title: NPS
     * Insight ID: aaeaQqKnsp9M
     */ NPS: "aaeaQqKnsp9M",
    /**
     * Insight Title: Loyalty
     * Insight ID: aadP7G8DNxu1
     */ Loyalty: "aadP7G8DNxu1",
    /**
     * Insight Title: Trust
     * Insight ID: aaiP5VEoysvJ
     */ Trust: "aaiP5VEoysvJ",
    /**
     * Insight Title: Income
     * Insight ID: aciOHnnTqy3G
     */ Income: "aciOHnnTqy3G",
    /**
     * Insight Title: Education
     * Insight ID: acxOG49TqyfB
     */ Education_1: "acxOG49TqyfB",
    /**
     * Insight Title: Age
     * Insight ID: aaMOJCtjqhiI
     */ Age_1: "aaMOJCtjqhiI",
    /**
     * Insight Title: Responses FILTERED
     * Insight ID: aayO5mAGmC0o
     */ ResponsesFILTERED: "aayO5mAGmC0o",
    /**
     * Insight Title: Survey respondents UNFILTERED
     * Insight ID: aajaJlPWrJPM
     */ SurveyRespondentsUNFILTERED: "aajaJlPWrJPM",
    /**
     * Insight Title: Survey score
     * Insight ID: aac4GCFWLP5X
     */ SurveyScore: "aac4GCFWLP5X",
    /**
     * Insight Title: Survey dimension score
     * Insight ID: aajJlhifZQ60
     */ SurveyDimensionScore_1: "aajJlhifZQ60",
    /**
     * Insight Title: Perception
     * Insight ID: aabP96xmNy1n
     */ Perception: "aabP96xmNy1n",
    /**
     * Insight Title: Segmentation
     * Insight ID: aadP9IBbyeJl
     */ Segmentation: "aadP9IBbyeJl",
    /**
     * Insight Title: Social responsibilty
     * Insight ID: aaiPPT0EIxoM
     */ SocialResponsibilty: "aaiPPT0EIxoM",
    /**
     * Insight Title: Differentiation
     * Insight ID: aacP6reYysgi
     */ Differentiation: "aacP6reYysgi",
    /**
     * Insight Title: Country score (is)
     * Insight ID: aatcfq7m2FbC
     */ CountryScoreIs: "aatcfq7m2FbC",
    /**
     * Insight Title: Dimension Country score
     * Insight ID: aar8RkGxC4td
     */ DimensionCountryScore: "aar8RkGxC4td",
    /**
     * Insight Title: Brandr average
     * Insight ID: aabcseav8pWx
     */ BrandrAverage: "aabcseav8pWx",
    /**
     * Insight Title: Highest 5% surveys
     * Insight ID: aabGOV9Zhnyp
     */ Highest5PercentSurveys: "aabGOV9Zhnyp",
    /**
     * Insight Title: Lowest 5% surveys
     * Insight ID: aadGIWdO4Iow
     */ Lowest5PercentSurveys: "aadGIWdO4Iow",
    /**
     * Insight Title: Dimension score Brandr average
     * Insight ID: aagnOK3zRsaz
     */ DimensionScoreBrandrAverage: "aagnOK3zRsaz",
    /**
     * Insight Title: Dimension highest 5% surveys
     * Insight ID: aadGMzfo4V8e
     */ DimensionHighest5PercentSurveys: "aadGMzfo4V8e",
    /**
     * Insight Title: Dimension lowest 5% surveys
     * Insight ID: aadGMxnWKaNY
     */ DimensionLowest5PercentSurveys: "aadGMxnWKaNY",
    /**
     * Insight Title: NPS Distribution
     * Insight ID: aajg72v5Hwwe
     */ NPSDistribution: "aajg72v5Hwwe",
    /**
     * Insight Title: Loyalty Distribution
     * Insight ID: aaiMzw2XfQu5
     */ LoyaltyDistribution: "aaiMzw2XfQu5",
    /**
     * Insight Title: Trust Distribution
     * Insight ID: aamMxmXqfSm4
     */ TrustDistribution: "aamMxmXqfSm4",
    /**
     * Insight Title: Score w dimensions
     * Insight ID: aakyBO0MAIiF
     */ ScoreWDimensions: "aakyBO0MAIiF",
    /**
     * Insight Title: Answer distribution for score questions
     * Insight ID: aabebqRRUrIU
     */ AnswerDistributionForScoreQuestions: "aabebqRRUrIU",
    /**
     * Insight Title: NPS Score breakdown
     * Insight ID: aabsI3XwkJEj
     */ NPSScoreBreakdown: "aabsI3XwkJEj",
    /**
     * Insight Title: Dimension weight
     * Insight ID: aabyzpjsFQXN
     */ DimensionWeight_1: "aabyzpjsFQXN",
    /**
     * Insight Title: Trust Score breakdown
     * Insight ID: aajMAZJZynnd
     */ TrustScoreBreakdown: "aajMAZJZynnd",
    /**
     * Insight Title: Loyalty Score breakdown
     * Insight ID: aamMvzrZylZb
     */ LoyaltyScoreBreakdown: "aamMvzrZylZb",
    /**
     * Insight Title: Question list
     * Insight ID: aadsJSJmkJm6
     */ QuestionList: "aadsJSJmkJm6",
    /**
     * Insight Title: Education (custom widget)
     * Insight ID: aaczd2kH9Zx6
     */ EducationCustomWidget: "aaczd2kH9Zx6",
    /**
     * Insight Title: Top 10 % free text
     * Insight ID: aaeaRpnBsmsZ
     */ Top10PercentFreeText: "aaeaRpnBsmsZ",
    /**
     * Insight Title: Dimension score by income
     * Insight ID: abQO7x6Kn3n9
     */ DimensionScoreByIncome: "abQO7x6Kn3n9",
    /**
     * Insight Title: Dimension score by age
     * Insight ID: aciO76ZjnWHf
     */ DimensionScoreByAge: "aciO76ZjnWHf",
    /**
     * Insight Title: Dimension score by gender
     * Insight ID: aaMO8YIsBd69
     */ DimensionScoreByGender: "aaMO8YIsBd69",
    /**
     * Insight Title: Dimension score by education
     * Insight ID: adnPabrABcxH
     */ DimensionScoreByEducation: "adnPabrABcxH",
    /**
     * Insight Title: Question list
     * Insight ID: aaePtp75yppC
     */ QuestionList_1: "aaePtp75yppC",
    /**
     * Insight Title: Custom widget stacked bar
     * Insight ID: aabsKRCqgNYw
     */ CustomWidgetStackedBar: "aabsKRCqgNYw",
    /**
     * Insight Title: JJI- test
     * Insight ID: aaERmnPvsIKy
     */ JJITest: "aaERmnPvsIKy",
    /**
     * Insight Title: Overview NPS
     * Insight ID: aaieE4lsnUp0
     */ OverviewNPS: "aaieE4lsnUp0",
    /**
     * Insight Title: Overview Loyalty
     * Insight ID: aaeeIlORotZT
     */ OverviewLoyalty: "aaeeIlORotZT",
    /**
     * Insight Title: Overview Trust
     * Insight ID: aaseB3LVo4kR
     */ OverviewTrust: "aaseB3LVo4kR",
    /**
     * Insight Title: Respondents
     * Insight ID: ab0fX7nBaDWy
     */ Respondents: "ab0fX7nBaDWy",
    /**
     * Insight Title: Gender (custom widget)
     * Insight ID: aacqI24QLYq5
     */ GenderCustomWidget: "aacqI24QLYq5",
    /**
     * Insight Title: Age (custom widget)
     * Insight ID: aabqI9hYLYvi
     */ AgeCustomWidget: "aabqI9hYLYvi",
    /**
     * Insight Title: Income (custom widget)
     * Insight ID: aazqlXkRzAUS
     */ IncomeCustomWidget: "aazqlXkRzAUS",
    /**
     * Insight Title: Answer distribution for score questions updated sorting
     * Insight ID: ablzWT28EKR3
     */ AnswerDistributionForScoreQuestionsUpdatedSorting: "ablzWT28EKR3",
    /**
     * Insight Title: Question list, custom widget w gantt position
     * Insight ID: aavexMICeb4s
     */ QuestionListCustomWidgetWGanttPosition: "aavexMICeb4s",
    /**
     * Insight Title: Age, custom widget w gantt start
     * Insight ID: aaketRFCS9wg
     */ AgeCustomWidgetWGanttStart: "aaketRFCS9wg",
    /**
     * Insight Title: Education, custom widget w gantt start
     * Insight ID: aacdgy3b9Ryp
     */ EducationCustomWidgetWGanttStart: "aacdgy3b9Ryp",
    /**
     * Insight Title: Gender, custom widget w gantt start
     * Insight ID: aajc5s23670O
     */ GenderCustomWidgetWGanttStart: "aajc5s23670O",
    /**
     * Insight Title: Income, custom widget w gantt start
     * Insight ID: aahc0Uuz6XoO
     */ IncomeCustomWidgetWGanttStart: "aahc0Uuz6XoO",
    /**
     * Insight Title: Respondents FILTERED_BLACK
     * Insight ID: aaCIbf6qk6uX
     */ RespondentsFILTEREDBLACK: "aaCIbf6qk6uX",
};
export const Dashboards = {
    /**
     * Dashboard Title: 2. Demographics
     * Dashboard ID: aazO3BU3moLY
     */
    _2Demographics: "aazO3BU3moLY",
    /**
     * Dashboard Title: 3. KPI
     * Dashboard ID: aachkAchu52m
     */ _3KPI: "aachkAchu52m",
    /**
     * Dashboard Title: 1. Overview
     * Dashboard ID: aatjCCXUSkqj
     */ _1Overview: "aatjCCXUSkqj",
    /**
     * Dashboard Title: 4. Dimension deep dive
     * Dashboard ID: aaoJhNGT9rxO
     */ _4DimensionDeepDive: "aaoJhNGT9rxO",
    /**
     * Dashboard Title: 5. Custom widget
     * Dashboard ID: aafsJSJmkJm6
     */ _5CustomWidget: "aafsJSJmkJm6",
};
