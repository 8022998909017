import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";

import BrandrLogo from "../../media/brandr-index-logo.svg";

//import { appName } from "../../constants";

import styles from "./Header.module.scss";

const Logo: React.FC = () => {
    return (
        <NavLink to="/" className={cx(styles.Logo)}>
            <>
                {/* {appName} */}
                <img src={BrandrLogo} alt="Brandr" />
            </>
        </NavLink>
    );
};

export default Logo;
