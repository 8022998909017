import { HeaderPredicates } from "@gooddata/sdk-ui";
import { IPositiveAttributeFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";

import { IComparisonData, IDeepDiveCmsData, IDifferentiationCmsData } from "./CmsValidators";
import { IHasNameUri } from "../form/OverviewForm";
import * as Md from "../../md/full";

export const sortedDeepDiveArr = [
    "comparison",
    "questionList",
    "pieChart",
    "gender",
    "income",
    "age",
    "education",
];

export const pieChartDrillableItems = [HeaderPredicates.identifierMatch("label.vanswers.answerscaledesc")];

export const sortCmsData = (
    cmsData: IDeepDiveCmsData | IDifferentiationCmsData | IComparisonData,
    callback: (value: React.SetStateAction<{}[]>) => void,
    wantedOrder: string[],
) => {
    let tempArray = [{}];
    if (cmsData) {
        // eslint-disable-next-line array-callback-return
        wantedOrder.map((item) => {
            const dataFound = Object.keys(cmsData).find((i) => i === item);
            if (dataFound) {
                const newData = { [item]: cmsData[item][0] };
                tempArray.push(newData);
                callback(tempArray.slice(1));
            }
        });
    }
};

export const fetchAnswerScaleFilters = (scale: IHasNameUri | null): IPositiveAttributeFilter => {
    let scaleFilter = newPositiveAttributeFilter(Md.AnswerScale, []);

    if (scale) {
        scaleFilter = newPositiveAttributeFilter(Md.AnswerScale, { uris: [scale.uri] });
    }

    return scaleFilter;
};
