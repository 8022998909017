import { IWorkspaceDescriptor } from "@gooddata/sdk-backend-spi/esm/workspace";

export interface IWorkspaceSourceState {
    isLoading: boolean;
    error?: Error;
    data?: IWorkspaceDescriptor[];
}
export const defaultSourceState: IWorkspaceSourceState = { isLoading: true };

export function getDecimalPart(num: number | string) {
    const formattedNumber = Number(num).toFixed(2);
    if (Number.isInteger(formattedNumber)) {
        return 0;
    }

    const decimalStr = formattedNumber.toString().split(".")[1];
    return Number(decimalStr);
}
