import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withFormik, FormikProps } from "formik";
import { string, object } from "yup";
import { useWorkspaceStrict } from "@gooddata/sdk-ui";
import styles from "./LoginForm.module.scss";
import CustomLoading from "../CustomLoading";
import Input from "../controls/Input";
import Button from "../controls/Button";
import { FirebaseAuthContext } from "../../contexts/FirebaseAuth/FirebaseAuthContext";
import { useAuth } from "../../contexts/Auth";
import { AuthStatus } from "../../contexts/Auth/state";
import { addUserToFS } from "../../services/FirebaseServices";
import { createBackend } from "../../contexts/Auth/backend";
import BranderLoginLogo from "../../assets/BrandrLoginLogo.svg";
import * as userActions from "../../redux/actions/testActions";

export interface FormValues {
    email: string;
    password: string;
}

export interface MyFormProps extends FormValues, RouteComponentProps {
    login: (username: string, password: string) => Promise<void>;
    loginError?: string;
}

const LoginFormComponent: React.FC<MyFormProps & FormikProps<FormValues>> = (props) => {
    const dispatch = useDispatch();
    const fbContext = useContext(FirebaseAuthContext);
    const createdBackend = createBackend();
    const workspace = useWorkspaceStrict();

    const auth = useAuth();

    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, loginError } =
        props;

    const addUserToFirestore = async () => {
        const userData = await createdBackend.isAuthenticated();
        addUserToFS(values.email, userData);
    };

    useEffect(() => {
        if (auth.authStatus === AuthStatus.AUTHORIZED) {
            fbContext?.createUserFirebase(values.email, values.password);
            addUserToFirestore();
            dispatch(userActions.setCurrentUserData(createdBackend, workspace));

            if (fbContext?.createUserStatus === "auth/email-already-in-use") {
                fbContext?.login(values.email, values.password);
            } else {
                //createUserFirebase creates the user in FB if user does not exist
                fbContext?.login(values.email, values.password);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.authStatus, fbContext?.createUserStatus]);

    return (
        <div className={styles.LoginContainer}>
            <div className={styles.Center}>
                <div style={{ alignSelf: "flex-end", marginBottom: 32 }}>
                    <BranderLoginLogo />
                </div>
                <form onSubmit={handleSubmit}>
                    <Input
                        className={cx(styles.Input, "s-login-input-email")}
                        hasError={!!errors.email && touched.email}
                        placeholder="E-mail"
                        type="email"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="e-mail"
                    />
                    <Input
                        className={cx(styles.Input, "s-login-input-password")}
                        hasError={!!errors.password && touched.password}
                        placeholder="Password"
                        type="password"
                        name="password"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="password"
                    />
                    {loginError && <div className={styles.Error}>{loginError}</div>}
                    {errors.email && touched.email && <div className={styles.Error}>{errors.email}</div>}
                    {errors.password && touched.password && (
                        <div className={styles.Error}>{errors.password}</div>
                    )}
                    <Button type="submit" className={styles.SubmitButton} disabled={isSubmitting}>
                        {isSubmitting ? (
                            <>
                                <CustomLoading inline height="auto" imageHeight="0.8em" />
                                &emsp;Signing in...
                            </>
                        ) : (
                            "LOGIN"
                        )}
                    </Button>
                </form>
            </div>
        </div>
    );
};

const formikConnector = withFormik<MyFormProps, FormValues>({
    mapPropsToValues: ({ email = "", password = "" }) => ({
        email,
        password,
    }),
    validationSchema: object().shape({
        email: string().email("Invalid e-mail address").required("E-mail is required"),
        password: string().required("Password is required"),
    }),
    handleSubmit: ({ email, password }, { props: { login, history }, setFieldError, setSubmitting }) => {
        return login(email, password).then(
            () => history.push("/"),
            (error) => {
                setSubmitting(false);
                if (error.response && error.response.status === 401) {
                    setFieldError("password", "E-mail or password is invalid");
                } else {
                    setFieldError("password", "E-mail or password is invalid");
                }
            },
        );
    },
});

export default withRouter(formikConnector(LoginFormComponent));
