import React, { useEffect, useState } from "react";

import styled from "styled-components";
import CustomLoading from "./CustomLoading";

const ErrorView: React.FC = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    console.log({ windowSize });

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);
    return (
        <Container height={windowSize.innerHeight}>
            <CustomLoading label="Error in loading data." />
            <h3> Please refresh the page or try later.</h3>
        </Container>
    );
};

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

export default ErrorView;

const Container = styled.div<{ height: number }>`
    padding-top: 150px;
    text-align: center;
    height: ${(prop) => prop.height}px;
`;
