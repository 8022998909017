import { useEffect, useState, FC } from "react";
import Page from "../Page";

import { InsightView } from "@gooddata/sdk-ui-ext";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "graphql-hooks";
import { idRef, IPositiveAttributeFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { HeaderPredicates } from "@gooddata/sdk-ui";

import { RootState } from "../../redux/store";
import { OVERVIEW_QUERY } from "../Querys/OverviewQuery";
import { getCustomerCms } from "./query-validator";
import styles from "./Form.module.scss";
import { IOverviewCmsData } from "../utils/CmsValidators";
import CustomLoading from "../CustomLoading";
import * as Md from "../../md/full";
import { getDeepDiveFilter } from "../utils/FilterValidators";
import { clearAllSelectedFilters } from "../../redux/actions/testActions";
import ErrorView from "../ErrorView";

export interface IHasNameUri {
    name: string;
    uri: string;
}

export const positiveFilterInitialState = {
    filter: newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
};

const OverviewForm: FC = () => {
    const dispatch = useDispatch();
    const query = OVERVIEW_QUERY("allOverviews");
    const reduxIdFilter = useSelector((state: RootState) => state.testData.selectedSurveyFilter);
    const userWorkspace = useSelector(
        (state: RootState) => state.testData.currentUserSettings?.workspaces[0],
    );
    const filtersFromRedux = useSelector((state: RootState) => state.testData.insightFiltersToApply);
    const clearFilters = useSelector((state: RootState) => state.testData.clearAllSelectedFilters);
    const countryFilter = useSelector((state: RootState) => state.testData.countryFilter);
    const ageFilter = useSelector((state: RootState) => state.testData.ageFilter);
    const genderFilter = useSelector((state: RootState) => state.testData.genderFilter);
    const educationFilter = useSelector((state: RootState) => state.testData.educationFilter);
    const householdFilter = useSelector((state: RootState) => state.testData.householdFilter);
    const [cmsData, setCmsData] = useState<IOverviewCmsData | undefined>(undefined);
    const [surveyIdFilter, setSurveyIdFilter] = useState<IPositiveAttributeFilter>(
        positiveFilterInitialState.filter,
    );
    const [isExpanded, setIsExpanded] = useState(false);
    const [dimensionScoreComparisonFilter, setDimensionScoreComparisonFilter] =
        useState<IPositiveAttributeFilter>(surveyIdFilter);
    const [tableFilters, setTableFilters] = useState<IPositiveAttributeFilter>(
        positiveFilterInitialState.filter,
    );
    const [selectedIndex, setSelectedIndex] = useState<number>();

    const topTenAwarenessDrillableItems = [HeaderPredicates.identifierMatch("label.vanswers.valuetext")];

    const { loading, error, data } = useQuery(query, {
        variables: {
            limit: 100,
        },
    });

    const getDrillFilters = (
        state: IHasNameUri | null,
        awareness: IHasNameUri | null,
    ): IPositiveAttributeFilter => {
        let filter1 = newPositiveAttributeFilter(Md.ValueText, []);

        if (state) {
            filter1 = newPositiveAttributeFilter(Md.ValueText, { uris: [state.uri] });
        }

        if (awareness) {
            filter1 = newPositiveAttributeFilter(Md.ValueText, { uris: [awareness.uri] });
        }

        return filter1;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchCustomerCms = async () => {
        const customerOverview = await getCustomerCms(data.allOverviews, userWorkspace || "No-data");
        setCmsData(customerOverview);
    };

    const handleComparisonToggle = (id: string) => {
        setDimensionScoreComparisonFilter(getDeepDiveFilter(id));

        if (
            getDeepDiveFilter(id).positiveAttributeFilter.in["values"][0] ===
            dimensionScoreComparisonFilter.positiveAttributeFilter.in["values"][0]
        ) {
            setIsExpanded(!isExpanded);
        } else {
            setIsExpanded(true);
        }
    };

    function onAwarenesslDrill(drillTarget: any) {
        const { name, uri } =
            drillTarget.drillContext.element === "bar"
                ? drillTarget.drillContext.intersection[1].header.attributeHeaderItem
                : drillTarget.drillContext[0].intersection[1].header.attributeHeaderItem;

        const newAwareness: IHasNameUri = {
            name,
            uri,
        };

        setTableFilters(getDrillFilters(null, newAwareness));
    }

    useEffect(() => {
        if (clearFilters) {
            setTableFilters(getDrillFilters(null, null));
            dispatch(clearAllSelectedFilters(false));
        }
    }, [clearFilters, dispatch]);

    useEffect(() => {
        if (data && userWorkspace) {
            fetchCustomerCms();
        }
    }, [data, fetchCustomerCms, userWorkspace]);

    useEffect(() => {
        if (filtersFromRedux && reduxIdFilter) {
            const NewIdFilter = newPositiveAttributeFilter(reduxIdFilter.atrb, {
                uris: [reduxIdFilter.ar],
            });

            setSurveyIdFilter(NewIdFilter);
        }
    }, [filtersFromRedux, reduxIdFilter]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page showFilters={true}>
            <p>{cmsData?.introText || ""}</p>

            {cmsData ? (
                <div className={styles.CardContainer}>
                    <div className={styles.WidgetCardRow} style={{ flexDirection: "row" }}>
                        <div className={styles.WidgetContainer}>
                            <p>{cmsData.totalScore[0].heading}</p>

                            <div style={{ height: 200, width: 200 }}>
                                <InsightView
                                    insight={idRef(cmsData.totalScore[0].widgetId)}
                                    filters={[
                                        surveyIdFilter,
                                        countryFilter,
                                        ageFilter,
                                        genderFilter,
                                        educationFilter,
                                        householdFilter,
                                        tableFilters,
                                    ]}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <h2 style={{ height: 38, color: "#000000" }}>{cmsData.totalScore[0].heading}</h2>
                            <p>{cmsData.totalScore[0].description}</p>
                        </div>
                    </div>

                    <div className={styles.WidgetCardColumn}>
                        <h2 style={{ color: "#000000" }}>{cmsData.totalScoreComparison[0].heading}</h2>
                        <p>{cmsData.totalScoreComparison[0].description}</p>

                        <div className={styles.ScoreContainer}>
                            {cmsData.totalScoreComparison[0].widgets.map((widget, index) => (
                                <div key={index} className={styles.WidgetContainer}>
                                    {widget.heading === "Brandr score" ? (
                                        <>
                                            <p>{widget.heading}</p>
                                            <InsightView
                                                insight={widget.widgetId}
                                                filters={[
                                                    surveyIdFilter,
                                                    countryFilter,
                                                    ageFilter,
                                                    genderFilter,
                                                    educationFilter,
                                                    householdFilter,
                                                    tableFilters,
                                                ]}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    <p>{widget.heading}</p>
                                    <InsightView
                                        insight={widget.widgetId}
                                        filters={[
                                            surveyIdFilter,
                                            countryFilter,
                                            ageFilter,
                                            genderFilter,
                                            educationFilter,
                                            householdFilter,
                                        ]}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.WidgetCardRow}>
                        <div className={styles.DimensionWeightTitleContainer}>
                            <h2 style={{ color: "#000000" }}>{cmsData.dimensionWeight[0].heading}</h2>
                            <p>{cmsData.dimensionWeight[0].description}</p>
                        </div>

                        {cmsData.dimensionWeight[0].widgets.map((widget, index) => (
                            <div
                                key={index}
                                className={styles.DimensionWeightContainer}
                                style={{ justifyContent: "space-around" }}
                            >
                                <p style={{ height: "auto" }}>{widget.heading}</p>

                                <div className={styles.DimensionWeightInsightContainer}>
                                    <InsightView
                                        insight={widget.widgetId}
                                        filters={[
                                            surveyIdFilter,
                                            countryFilter,
                                            ageFilter,
                                            genderFilter,
                                            educationFilter,
                                            householdFilter,
                                            tableFilters,
                                        ]}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.WidgetCardColumn}>
                        <h2 style={{ color: "#000000" }}>{cmsData.dimensionScore[0].heading}</h2>
                        <p>{cmsData.dimensionScore[0].description}</p>

                        <div className={styles.ScoreContainer}>
                            {cmsData.dimensionScore[0].widgets.map((widget, index) => (
                                <div key={index} className={styles.CenterContentColumn}>
                                    <div className={styles.WidgetContainer}>
                                        <p>{widget.heading}</p>
                                        <InsightView
                                            insight={widget.widgetId}
                                            filters={[
                                                surveyIdFilter,
                                                countryFilter,
                                                ageFilter,
                                                genderFilter,
                                                educationFilter,
                                                householdFilter,
                                                tableFilters,
                                            ]}
                                        />
                                    </div>
                                    <button
                                        className={
                                            selectedIndex === index
                                                ? styles.SelectedComparisonBtn
                                                : styles.ComparisonBtn
                                        }
                                        onClick={(i) => {
                                            handleComparisonToggle(widget.heading);
                                            setSelectedIndex(index);
                                            console.log("INDEX: ", { INDEX: index });
                                            console.log("INDEX: ", { I: i });
                                        }}
                                    >
                                        COMPARE
                                    </button>
                                </div>
                            ))}
                        </div>
                        {isExpanded && (
                            <div className={styles.ComparisonContainer}>
                                {cmsData.dimensionScoreComparison[0].widgets.map((widget, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 32,
                                        }}
                                    >
                                        <div className={styles.WidgetContainer}>
                                            <p>{widget.heading}</p>
                                            <InsightView
                                                insight={widget.widgetId}
                                                filters={[
                                                    surveyIdFilter,
                                                    countryFilter,
                                                    ageFilter,
                                                    genderFilter,
                                                    educationFilter,
                                                    householdFilter,
                                                    dimensionScoreComparisonFilter,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className={styles.WidgetCardColumn}>
                        <div className={styles.TrustAndLoyaltyDescriptionContainer}>
                            <h2 style={{ color: "#000000" }}>{cmsData.trustAndLoyalty[0].heading}</h2>
                            <p>{cmsData.trustAndLoyalty[0].description}</p>
                        </div>

                        <div className={styles.TrustLoyaltyContainer}>
                            {cmsData.trustAndLoyalty[0].widgets.map((widget, index) => (
                                <div key={index} className={styles.CenterContentColumn}>
                                    <div className={styles.WidgetContainer}>
                                        <p>{widget.heading}</p>
                                        <InsightView
                                            insight={widget.widgetId}
                                            filters={[
                                                surveyIdFilter,
                                                countryFilter,
                                                ageFilter,
                                                genderFilter,
                                                educationFilter,
                                                householdFilter,
                                                tableFilters,
                                            ]}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.WidgetCardColumn}>
                        <h2 style={{ color: "#000000" }}>{cmsData.topTenAwareness[0].heading}</h2>
                        <p>{cmsData.topTenAwareness[0].description}</p>

                        <div className={styles.TopAwarenessContainer}>
                            {cmsData.topTenAwareness[0].widgets.map((widget, index) => (
                                <div key={index} className={styles.TopAwarenessInsightContainer}>
                                    <h4>{widget.heading}</h4>

                                    <InsightView
                                        insight={widget.widgetId}
                                        filters={[
                                            surveyIdFilter,
                                            countryFilter,
                                            ageFilter,
                                            genderFilter,
                                            educationFilter,
                                            householdFilter,
                                            tableFilters,
                                        ]}
                                        drillableItems={topTenAwarenessDrillableItems}
                                        onDrill={onAwarenesslDrill}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : error ? (
                <ErrorView />
            ) : loading ? (
                <div className={styles.LoadingDiv}>
                    <CustomLoading label="...Loading" />
                </div>
            ) : error ? (
                <ErrorView />
            ) : (
                <ErrorView />
            )}
        </Page>
    );
};

export default OverviewForm;
