import { Dispatch } from "react";
import { IAnalyticalBackend } from "@gooddata/sdk-backend-spi";
import { IAttribute, newPositiveAttributeFilter } from "@gooddata/sdk-model";

export const TEST_GET_DATA = "TEST_GET_DATA";
export const SET_CURRENT_USER_DATA = "SET_CURRENT_USER_DATA";
export const SET_USER_WORKSPACE = "SET_USER_WORKSPACE";
export const CLEAR_REDUX = "CLEAR_REDUX";
export const INSIGHT_FILTERS_TO_APPLY = "INSIGHT_FILTER_TO_APPLY";
export const SET_SELECTED_SURVEY_FILTER = "SET_SELECTED_SURVEY_FILTER";
export const CLEAR_ALL_SELECTED_FILTERS = "CLEAR_ALL_SELECTED_FILTERS";
export const COMPARE_REPORT_FILTER = "COMPARE_REPORT_FILTER";
export const SURVEY_NAME = "SURVEY_NAME";
export const SELECTED_SURVEY_NAME = "SELECTED_SURVEY_NAME";
export const SET_COUNTRY_FILTER = "SET_COUNTRY_FILTER";
export const SET_AGE_FILTER = "SET_AGE_FILTER";
export const SET_GENDER_FILTER = "SET_GENDER_FILTER";
export const SET_EDUCATION_FILTER = "SET_EDUCATION_FILTER";
export const SET_HOUSEHOLD_FILTER = "SET_HOUSEHOLD_FILTER";

export const setTestData = (testString: string) => {
    return {
        type: TEST_GET_DATA,
        payload: testString,
    };
};

export const selectedSurveyName = (name: string) => {
    return {
        type: SELECTED_SURVEY_NAME,
        payload: name,
    };
};

export const setCurrentUserData = (backend: IAnalyticalBackend, workspace: string) => {
    return async (dispatch: Dispatch<any>) => {
        const currentUser = await backend.currentUser().getUser();
        const currentUserSettings = await backend.currentUser().settings().getSettings();
        const userPermission = await backend
            .workspace(workspace)
            .permissions()
            .getPermissionsForCurrentUser();
        const firstPageOfWorkspaces = await backend.workspaces().forCurrentUser().query();
        const allPagesOfWorkspaces = await firstPageOfWorkspaces.all();
        const userDescriptor = await allPagesOfWorkspaces[0].getDescriptor();

        console.log("USER DESCRIPTOR");
        console.log({ userDescriptor });

        dispatch({
            type: SET_CURRENT_USER_DATA,
            payload: {
                email: currentUser.email,
                fullName: currentUser.fullName,
                clientID: userDescriptor.title,
                workspaces: [workspace],
                userID: currentUserSettings.userId,
                canManageProject: userPermission.canManageProject,
            },
        });
    };
};

export const setUserWorkspace = (workspace: string) => {
    return {
        type: SET_USER_WORKSPACE,
        payload: workspace,
    };
};
export const clearRedux = () => {
    return {
        type: CLEAR_REDUX,
    };
};

export const insightFiltersToApply = (attribute: IAttribute, array: string[]) => {
    const filterArray = {
        atrb: attribute,
        ar: array,
    };

    return {
        type: INSIGHT_FILTERS_TO_APPLY,
        payload: filterArray,
    };
};

export const setCountryFilter = (attribute: IAttribute, array: string[]) => {
    const filter = newPositiveAttributeFilter(attribute, {
        uris: array,
    });

    return {
        type: SET_COUNTRY_FILTER,
        payload: filter,
    };
};

export const setAgeFilter = (attribute: IAttribute, array: string[]) => {
    const filter = newPositiveAttributeFilter(attribute, {
        uris: array,
    });

    return {
        type: SET_AGE_FILTER,
        payload: filter,
    };
};

export const setGenderFilter = (attribute: IAttribute, array: string[]) => {
    const filter = newPositiveAttributeFilter(attribute, {
        uris: array,
    });

    return {
        type: SET_GENDER_FILTER,
        payload: filter,
    };
};

export const setEducationFilter = (attribute: IAttribute, array: string[]) => {
    const filter = newPositiveAttributeFilter(attribute, {
        uris: array,
    });

    return {
        type: SET_EDUCATION_FILTER,
        payload: filter,
    };
};

export const setHouseholdFilter = (attribute: IAttribute, array: string[]) => {
    const filter = newPositiveAttributeFilter(attribute, {
        uris: array,
    });

    return {
        type: SET_HOUSEHOLD_FILTER,
        payload: filter,
    };
};

export const setSelectedYearFilter = (attributeID: IAttribute, attributeValue: string) => {
    const surveyFilter = {
        atrb: attributeID,
        ar: attributeValue,
    };

    return {
        type: SET_SELECTED_SURVEY_FILTER,
        payload: surveyFilter,
    };
};

export const setSelectedCompareFilter = (attributeID: IAttribute | null, attributeValue: string | null) => {
    if (attributeID) {
        const surveyFilter = {
            atrb: attributeID,
            ar: attributeValue,
        };

        return {
            type: COMPARE_REPORT_FILTER,
            payload: surveyFilter,
        };
    } else {
        return {
            type: COMPARE_REPORT_FILTER,
            payload: null,
        };
    }
};

export const setComparedSurveyName = (name: string) => {
    return {
        type: SURVEY_NAME,
        payload: name,
    };
};

export const clearAllSelectedFilters = (clearFilters: boolean) => {
    return {
        type: CLEAR_ALL_SELECTED_FILTERS,
        payload: clearFilters,
    };
};
