export const KEY_INDICATOR_QUERY = (query: string): string => {
    return `query KeyIndicator { 
        ${query} {
            title
            customer
            keyIndicators {
                ... on TitleBlockRecord {
                    title
                    content {
                        ... on WidgetHeadingDescriptionRecord {
                            heading
                            description
                            widgetId
                        }
                    }
                }
            }
        }
    }`;
};
