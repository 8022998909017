// The name of the application. It is rendered in a header of the web application.
export const appName = "brandr-web";

// The URL of analytical GoodData backend with workspaces, insights, metrics, attributes, datasets, and users
// that will be available to the application.
//export const backend = "https://brandranalytics-dev.on.gooddata.com";
export const backend = "https://brandranalytics.on.gooddata.com/";

export const forgotPasswordUrl =
    "https://brandranalytics.on.gooddata.com/account.html?lastUrl=https%253A%252F%252Fbrandranalytics.on.gooddata.com%252F#/lostPassword";

// The ID of workspace that is selected by default in the optional workspace picker component (located in the web
// application header). It is also a default setting for script that refreshes MD data used by the application.
// MD script shows list of available workspaces on backend in the case when the value is not set to any ID.
//export const workspace = "whr4wo8i857mlyius0p1w78kmvrulu3i";
//export const workspace = "uud5ia5htmag4s9gg6mhlunwrvw8zwez";
export const workspace = "r94cvmtg1y2gmuv99eb75g3c6i7gsam1";

// RegExp used by optional workspace picker component (located in the web application header) to filter out
// workspaces that should not be rendered to the application user. Only the workspaces with title that match
// the regular expression will be rendered. Set to 'undefined' to show all the available workspaces.
// Example: /test/ or /^GoodData/
export const workspaceFilter: RegExp | undefined = undefined;
