import * as React from "react";

const ImageAndPerceptionIcon = (props) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11 14H9a9 9 0 0 1 9-9v2c-3.87 0-7 3.13-7 7Zm7-3V9c-2.76 0-5 2.24-5 5h2c0-1.66 1.34-3 3-3ZM7 4c0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2 1.11 0 2-.89 2-2Zm4.45.5h-2A2.99 2.99 0 0 1 6.5 7h-3C2.67 7 2 7.67 2 8.5V11h6V8.74a4.97 4.97 0 0 0 3.45-4.24ZM19 17c1.11 0 2-.89 2-2 0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2Zm1.5 1h-3a2.99 2.99 0 0 1-2.95-2.5h-2A4.97 4.97 0 0 0 16 19.74V22h6v-2.5c0-.83-.67-1.5-1.5-1.5Z"
            fill="#FFFFFF"
        />
    </svg>
);

export default ImageAndPerceptionIcon;
