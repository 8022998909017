import React, { useMemo, useCallback, useState, useRef, useEffect } from "react";

import { DataPoint, IDataSlice, useExecution, useExecutionDataView } from "@gooddata/sdk-ui";
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, ResponsiveContainer, Customized } from "recharts";
import { IAttribute, IMeasure, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import ReactTooltip from "react-tooltip";

import "./BarChart.css";

import BarShape from "./BarShape";
import { BaseAxisProps } from "recharts/types/util/types";
import { getTextWidth } from "./utils";

type SlicePointValue = {
    [key: string]: {
        scale: string;
        scaleNames: string[];
        count: DataPoint[];
        size: DataPoint;
    };
};

type SlicePoint = {
    slice: IDataSlice;
    label: string;
    score: DataPoint;
    negativeResponses: DataPoint;
    start: DataPoint;
    values: SlicePointValue;
    entries?: any;
};

const CustomizedAxisTick: BaseAxisProps["tick"] = (props) => {
    const { y, width, payload, onYAxisClick, YAxisTextWidth, setYAxisTextWidth } = props;

    useEffect(() => {
        const width = getTextWidth(payload.value, "normal 1em Roboto") + 10;
        if (width > YAxisTextWidth) {
            setYAxisTextWidth(width);
        }
    }, [YAxisTextWidth, payload.value, setYAxisTextWidth]);

    return (
        <foreignObject
            {...props}
            width={width + 10}
            x={5}
            y={y - 25}
            className="bar-rectangle"
            onClick={() => onYAxisClick(payload)}
        >
            <p className="bar-y-tick">{payload.value}</p>
        </foreignObject>
    );
};

interface IADParameters {
    colors: string[];
    hideXLabels?: boolean;
    hideYLabels?: boolean;
    gridLines?: "all" | "middle" | "none";
    answerScale?: any;
}

const BarChart: React.FC<any> = (props) => {
    const {
        filters,
        sortBy,
        attributes,
        measures,
        setFilters,
        setAnswerScale,
        answerScale: currentAnswerScale,
        dimensions,
    } = props;
    const [questionName] = attributes as IAttribute[];
    const [, answerCount] = measures as IMeasure[];

    const containerRef = useRef<HTMLDivElement>(null);
    const [baseData, setBaseData] = useState<SlicePoint[]>([]);
    const [filtersData, setFiltersData] = useState<any>([]);

    const chartWidth = containerRef?.current?.getBoundingClientRect()?.width;

    const [YAxisTextWidth, setYAxisTextWidth] = useState(1);
    const [positions, setPositions] = useState({});

    const preparedExecution = useExecution({
        seriesBy: measures,
        slicesBy: attributes,
        filters,
        sortBy,
    }).withDimensions(...dimensions);

    const execution = useExecutionDataView({
        execution: preparedExecution,
    });

    const slices = execution.result?.data().slices().toArray();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const slicePoints: SlicePoint[] = [];

    execution.result
        ?.data()
        .slices()
        .toArray()
        .forEach((s) => {
            const [label, scale, scaleName] = s.sliceTitles();
            const slicePointIndex = slicePoints.findIndex((p) => p.label === label);
            const [score, count, negativeResponses, start, size] = s.dataPoints();
            if (slicePointIndex === -1) {
                const values = {
                    [scale]: {
                        scale,
                        scaleNames: [scaleName],
                        count: [count],
                        size,
                    },
                };
                return slicePoints.push({
                    slice: s,
                    label,
                    score,
                    negativeResponses,
                    start,
                    values,
                });
            }

            let slicePoint = slicePoints[slicePointIndex];
            const slicePointValue = slicePoint.values[scale];

            return (slicePoints[slicePointIndex] = {
                ...slicePoint,
                values: slicePointValue
                    ? {
                          ...slicePoint.values,
                          [scale]: {
                              scale,
                              scaleNames: [...slicePointValue.scaleNames, scaleName],
                              count: [...slicePointValue.count, count],
                              size,
                          },
                      }
                    : {
                          ...slicePoint.values,
                          [scale]: { scale, scaleNames: [scaleName], count: [count], size },
                      },
            });
        });

    const {
        colors,
        hideYLabels,
        gridLines = "middle",
    }: IADParameters = JSON.parse(answerCount.measure.format as string);

    const barMaxWidth = slices
        ? Math.max(
              ...(slices?.map((slice) =>
                  slice.dataPoints().reduce((prev, curr) => prev + Number(curr.rawValue), 0),
              ) as number[]),
          )
        : 0;

    const tickFormatter = useCallback(
        (value) => `${((Number(value) / barMaxWidth) * 100).toFixed(0)}%`,
        [barMaxWidth],
    );

    const ticks: number[] = useMemo(() => {
        const xPoints: number[] = [];
        const chunk: number = barMaxWidth / 15;
        for (let index = 0; index < 11; index++) {
            xPoints.push(chunk * index);
        }
        return [...xPoints.map((point) => -point), ...xPoints];
    }, [barMaxWidth]);

    const onYAxisClick = useCallback(
        (payload) => {
            const value = slicePoints?.find((s) => s.label === payload.value);
            setFilters(
                newPositiveAttributeFilter(questionName, {
                    // @ts-ignore
                    uris: [value?.start?.sliceDesc?.headers[0]?.attributeHeaderItem.uri],
                }),
            );
        },
        [setFilters, slicePoints, questionName],
    );

    // const renderLegend: ContentType = () => {
    //     return (
    //         <div className="custom-legend">
    //             {/* {
    //         scales.map((scale, index) => (
    //           <div className="custom-legend-row">
    //             <div
    //               className="block"
    //               style={{ backgroundColor: colors[index] }}
    //               key={`item-${index}`}>
    //               {scale}
    //             </div>
    //             <p></p>
    //           </div>
    //         ))
    //       } */}
    //             <div className="custom-legend-row">
    //                 <div className="block" style={{ backgroundColor: colors[4] }}>
    //                     5
    //                 </div>
    //                 <p>Strongly Agree</p>
    //             </div>
    //             <div className="custom-legend-row">
    //                 <div className="block" style={{ backgroundColor: colors[3] }}>
    //                     4
    //                 </div>
    //                 <p>Agree</p>
    //             </div>
    //             <div className="custom-legend-row">
    //                 <div className="block" style={{ backgroundColor: colors[2] }}>
    //                     3
    //                 </div>
    //                 <p>Neutral</p>
    //             </div>
    //             <div className="custom-legend-row">
    //                 <div className="block" style={{ backgroundColor: colors[1] }}>
    //                     2
    //                 </div>
    //                 <p>Disagree</p>
    //             </div>
    //             <div className="custom-legend-row">
    //                 <div className="block" style={{ backgroundColor: colors[0] }}>
    //                     1
    //                 </div>
    //                 <p>Strongly disagree</p>
    //             </div>
    //         </div>
    //     );
    // };

    const MiddleLine = ({ offset: { width, height, left, top } }: any) => (
        <line
            x1={left + width / 2}
            x2={left + width / 2}
            y1={top}
            y2={height + top}
            strokeWidth={1}
            stroke="#000"
            strokeDasharray={4}
        />
    );

    useEffect(() => {
        const compareFilter = filters.find(
            (f: any) =>
                f?.positiveAttributeFilter?.displayForm?.identifier === "label.scoreformulation.score_name",
        );
        const compareFilterSaved = filtersData.find(
            (f: any) =>
                f?.positiveAttributeFilter?.displayForm?.identifier === "label.scoreformulation.score_name",
        );
        if (
            (!baseData.length ||
                (compareFilter &&
                    compareFilter?.positiveAttributeFilter.in.values[0] !==
                        compareFilterSaved?.positiveAttributeFilter.in.values[0])) &&
            execution.status === "success" &&
            slicePoints.length
        ) {
            setBaseData(slicePoints.filter((s) => !!s.start.rawValue));
            setFiltersData(filters);
        }
    }, [slicePoints, execution, filtersData, filters, baseData.length]);

    const filteredData = slicePoints.filter((s) => baseData.find((b) => b.label === s.label));

    return (
        <div style={{ height: 100 + (filteredData?.length || 1) * 38 }} ref={containerRef}>
            <ReactTooltip multiline effect="float" arrowColor="transparent" className="custom-tooltip" />
            <ResponsiveContainer width="100%" height="100%">
                <RechartsBarChart
                    data={filteredData}
                    layout="vertical"
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    {/* {gridLines === "all" && <CartesianGrid horizontal={false} strokeDasharray="0" />} */}
                    <XAxis
                        ticks={ticks}
                        domain={[-barMaxWidth, barMaxWidth]}
                        interval={0}
                        hide={true}
                        tickSize={0}
                        tick={{ fill: "rgb(166, 166, 166)", fontSize: 12 }}
                        tickMargin={10}
                        tickFormatter={tickFormatter}
                        type="number"
                    />
                    <YAxis
                        type="category"
                        interval={0}
                        tickMargin={10}
                        tickSize={0}
                        width={
                            YAxisTextWidth > (chartWidth || 1) * 0.3
                                ? (chartWidth || 1) * 0.3
                                : YAxisTextWidth
                        }
                        hide={hideYLabels}
                        tick={
                            <CustomizedAxisTick
                                YAxisTextWidth={YAxisTextWidth}
                                setYAxisTextWidth={setYAxisTextWidth}
                                onYAxisClick={onYAxisClick}
                            />
                        }
                        dataKey="label"
                    />
                    {/* <Tooltip cursor={{ fill: 'rgb(244, 245, 250)' }} /> */}
                    {/* <Legend layout="vertical" align="right" verticalAlign="top" content={renderLegend} /> */}
                    <Bar
                        shape={(shape) => (
                            <BarShape
                                {...shape}
                                colors={colors}
                                setAnswerScale={setAnswerScale}
                                answerScale={currentAnswerScale}
                                filters={filters}
                                positions={positions}
                                setPositions={setPositions}
                            />
                        )}
                        dataKey={`values`}
                        barSize={30}
                    />
                    {gridLines !== "none" && <Customized component={MiddleLine} />}
                </RechartsBarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChart;
