import React from "react";

import LogoutForm from "../components/Auth/LogoutForm";
import { useAuth } from "../contexts/Auth";

import pageStyles from "../components/Page.module.scss";

const Logout: React.FC = () => {
    const { logout } = useAuth();

    return (
        <div className={pageStyles.Inverse}>
            <LogoutForm logout={logout} />
        </div>
    );
};

export default Logout;
