import { useEffect, useMemo, useCallback } from "react";
import { Dot, Rectangle } from "recharts";
import ReactTooltip from "react-tooltip";
import { renderToString } from "react-dom/server"; // https://github.com/wwayne/react-tooltip#jsx-note

import { DataPoint, NullableFiltersOrPlaceholders } from "@gooddata/sdk-ui";

export interface IShapeValue {
    color: string;
    labels: string[];
    percentage: number | string;
    scale: number | string;
    scaleURI: string;
    total: number;
    values: IShapeValue[];
    x: number;
    scoreX?: number;
    score: DataPoint;
    width: number;
    start: DataPoint;
    size: DataPoint;
    count: DataPoint[];
}
interface ICustomBar {
    fill: string;
    x: number;
    y: number;
    width: number;
    height: number;
    background: {
        width: number;
        height: number;
        x: number;
        y: number;
    };
    payload: any;
    values: IShapeValue[];
    barMaxWidth: number;
    value: number[];
    datasets: number;
    scale: number;
    colors: string[];
    scorePositions: Array<{ cx: number; label: string }>;
    setscorePositions: any;
    label: string;
    setAnswerScale: (scale: unknown) => void;
    answerScale: unknown;
    score?: DataPoint;
    start: DataPoint;
    filters?: NullableFiltersOrPlaceholders;
    setPositions: (data: any) => void;
    positions: any[];
}

const BarShape = (props: ICustomBar) => {
    const {
        x,
        y,
        background,
        score,
        height,
        values: rawValues,
        label,
        setAnswerScale,
        colors,
        start,
        positions,
        setPositions,
    } = props;
    const valueEntries: IShapeValue[] = Object.values(rawValues);
    const middlePoint = Math.round(valueEntries.length / 2);
    const savedValue: IShapeValue = positions[label];

    const values = useMemo(() => {
        if (valueEntries.length && background.width && x && start) {
            return valueEntries.map((val, index, arr) => ({
                ...val,
                width: (background.width / 2) * Number(val.size.rawValue || 0),
                x:
                    index === 0
                        ? x
                        : arr
                              .slice(0, index)
                              .reduce(
                                  (prev, curr) =>
                                      prev + (background.width / 2) * Number(curr?.size.rawValue || 0),
                                  x,
                              ),
                start,
            }));
        }
        return [];
    }, [valueEntries, background, x, start]);

    let scoreX = x;
    // If average score is greater than 3
    if (Number(score?.rawValue) > 3 && Math.sign(Number(score?.rawValue) - middlePoint) === 1) {
        const percentage = (Number(score?.rawValue) - 3) / 2;
        const lastTwoAndHalfWidth = values
            .slice(middlePoint - 1, valueEntries.length)
            .reduce((prev, curr, i) => (i === 0 ? curr.width / 2 : prev + curr.width), 0);
        const pixels = lastTwoAndHalfWidth * percentage;
        scoreX += pixels;
        // If average score is less than 3
    } else if (Number(score?.rawValue) - 3 !== 0) {
        const percentage = ((Number(score?.rawValue) - 3) * -1) / 2;
        const firstTwoAndHalfWidth = values
            .slice(0, middlePoint)
            .reduce(
                (prev, curr, i) => (i === middlePoint - 1 ? prev + curr.width / 2 : prev + curr.width),
                0,
            );
        const pixels = firstTwoAndHalfWidth * percentage;
        scoreX -= pixels;
    }

    const tooltipContent = useCallback(
        (value: any) => {
            if (!value) return null;
            const { scaleNames, count, size } = value;
            const allCount = count.reduce(
                (prev: number, curr: DataPoint) => prev + Number(curr?.rawValue),
                0,
            );
            return (
                <div className="custom-tooltip-body">
                    <p>
                        <b>{label}</b>
                    </p>
                    <p>
                        Response:{" "}
                        {scaleNames.map(
                            (label: string, i: number) => label + (i === scaleNames.length - 1 ? "" : ", "),
                        )}
                    </p>
                    <p>Count: {Math.round(allCount)}</p>
                    <p>Percentage: {Number(size?.rawValue * 100).toFixed(2)}%</p>
                </div>
            );
        },
        [label],
    );

    const onClick = (val: any) => {
        const [, uriObj] = val?.size.sliceDesc.headers;
        const {
            attributeHeaderItem: { name, uri },
        } = uriObj;
        setAnswerScale({ name: name, uri });
        ReactTooltip.hide();
    };

    useEffect(() => {
        if (
            valueEntries.length > 1 &&
            values &&
            values.length === valueEntries.length &&
            (!savedValue || savedValue.scoreX !== scoreX)
        ) {
            setPositions((prev: any) =>
                Object.assign(prev, {
                    [label]: {
                        label,
                        values,
                        scoreX,
                        score,
                        start,
                    },
                }),
            );
        }
    }, [savedValue, values, valueEntries.length, scoreX, setPositions, label, score, start]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <>
            {values &&
                savedValue?.values &&
                values.map((v, index) => {
                    const value = values.length > 1 ? v : savedValue.values.find((s) => s.scale === v.scale);
                    return (
                        <g key={index} data-html data-tip={renderToString(tooltipContent(value) || <div />)}>
                            <Rectangle
                                width={values.length === 1 ? value?.width : value?.width}
                                height={height}
                                x={
                                    values.length === 1
                                        ? (value?.x || 0) -
                                          (background.width / 2) * (Number(value?.start?.rawValue) * -1)
                                        : (value?.x || 0) -
                                          (background.width / 2) * (Number(value?.start?.rawValue) * -1)
                                }
                                y={y}
                                onClick={() => onClick(value)}
                                className="bar-rectangle"
                                fill={colors[Number(value?.scale) - 1]}
                            />
                        </g>
                    );
                })}
            <g id="score">
                {values.length === 1 && savedValue ? (
                    <>
                        <Dot
                            cx={savedValue?.scoreX}
                            cy={y + height / 2}
                            r={height / 2}
                            fill="rgb(126, 126, 126)"
                        />
                        <text
                            textAnchor="middle"
                            fontSize={12}
                            fill="#FFF"
                            x={savedValue?.scoreX}
                            y={y + height / 2 + 4}
                        >
                            {Number(savedValue?.score?.rawValue).toFixed(2) || ""}
                        </text>
                    </>
                ) : (
                    <>
                        <Dot cx={scoreX} cy={y + height / 2} r={height / 2} fill="rgb(126, 126, 126)" />
                        <text textAnchor="middle" fontSize={12} fill="#FFF" x={scoreX} y={y + height / 2 + 4}>
                            {Number(score?.rawValue).toFixed(2) || ""}
                        </text>
                    </>
                )}
            </g>
        </>
    );
};

export default BarShape;
