import { BackendProvider } from "@gooddata/sdk-ui";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { PersistGate } from "redux-persist/integration/react";

import AppRouter from "./routes/AppRouter";
import { useAuth } from "./contexts/Auth";
import { WorkspaceListProvider } from "./contexts/WorkspaceList";
import { store, persistor } from "./redux/store";
import { FbAuthContextProvider } from "./contexts/FirebaseAuth/FirebaseAuthContext";
import { fbInit } from "./services/FirebaseServices";
import { GraphQLClient, ClientContext } from "graphql-hooks";

function App() {
    const { backend } = useAuth();

    fbInit();

    const client = new GraphQLClient({
        url: "https://graphql.datocms.com/",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer 77253e9e549cbb257ad06a523ff55b",
        },
    });

    return (
        <CookiesProvider>
            <FbAuthContextProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <BackendProvider backend={backend}>
                            <WorkspaceListProvider>
                                <ClientContext.Provider value={client}>
                                    <AppRouter />
                                </ClientContext.Provider>
                            </WorkspaceListProvider>
                        </BackendProvider>
                    </PersistGate>
                </Provider>
            </FbAuthContextProvider>
        </CookiesProvider>
    );
}

export default App;
