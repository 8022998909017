export const COMPARISON_QUERY = (query: string): string => {
    return `query KeyIndicator { 
        ${query} (orderBy: title_DESC){
            title
            customer
            section1 {
                id
                widgetId
                heading
            }
            deepdiveComparison {
                widgetId
                heading
            }
            deepdiveContent {
                widgetId
                heading
            }
            demographics {
                heading
                description
                widgets {
                    heading
                    widgetId
                  }
            }
            kpis {
                heading
                description
                widgets {
                    heading
                    widgetId
                  }
            }
            top10Awareness {
                heading
                description
                widgets {
                    heading
                    widgetId
                  }
            }
        }
    }`;
};
