import { useQuery } from "graphql-hooks";

import Button from "../controls/Button";
import CustomLoading from "../CustomLoading";
import ErrorView from "../ErrorView";
import styles from "./Introduction.module.scss";

export interface IIntroduction {
    callback: () => void;
}

const Introduction: React.FC<IIntroduction> = ({ callback }) => {
    const query = `query Overview {
        overview { 
            introOverlay {
                heading
                description
                image {
                    url
                }
            }
         }
      }`;

    const { loading, error, data } = useQuery(query, {
        variables: {
            limit: 2,
        },
    });

    return (
        <div className={styles.IntroductionPageContainer}>
            <div className={styles.IntroductionContent}>
                <Button
                    onClick={() => {
                        callback();
                    }}
                >
                    Close page
                </Button>

                {data ? (
                    <>
                        <h1>{data.overview.introOverlay[0].heading}</h1>
                        <p>{data.overview.introOverlay[0].description}</p>
                        <img
                            src={data.overview.introOverlay[0].image.url}
                            style={{ width: "100%" }}
                            alt={require("../../assets/images/login-background.png")}
                        />
                    </>
                ) : error ? (
                    <ErrorView />
                ) : loading ? (
                    <CustomLoading label="...Loading" />
                ) : (
                    <ErrorView />
                )}
            </div>
        </div>
    );
};

export default Introduction;
