import { idRef, IPositiveAttributeFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { InsightView } from "@gooddata/sdk-ui-ext";
import { useQuery } from "graphql-hooks";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { HeaderPredicates } from "@gooddata/sdk-ui";

import Page from "../Page";
import { RootState } from "../../redux/store";
import { getCustomerCms } from "./query-validator";
import { IComparisonData } from "../utils/CmsValidators";
import styles from "./Form.module.scss";
import styless from "./Comparison.module.scss";
import { sortCmsData } from "../utils/DeepDiveValidators";
import CustomLoading from "../CustomLoading";
import * as Md from "../../md/full";
import { COMPARISON_QUERY } from "../Querys/ComparisonQuery";
import SurveyList from "../../routes/SurveyList";
import btnStyle from "../../routes/SurveyListStyle.module.scss";
import { setSelectedCompareFilter, clearAllSelectedFilters } from "../../redux/actions/testActions";
import { getDeepDiveFilter } from "../utils/FilterValidators";
import { IHasNameUri } from "./OverviewForm";
import { CustomWidget } from "./DifferentiationForm";
import { ComparedPage } from "./ComparedPage";

export const sordedComparisonArr = [
    "section1",
    "deepdiveComparison",
    "deepdiveContent",
    "demographics",
    "kpis",
    "top10Awareness",
];

const positiveFilterInitialState = {
    filter: newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
};

const ComparisonForm: React.FC = () => {
    const query = COMPARISON_QUERY("allComparisons");
    const dispatch = useDispatch();
    const userWorkspace = useSelector(
        (state: RootState) => state.testData.currentUserSettings?.workspaces[0],
    );
    const filtersFromRedux = useSelector((state: RootState) => state.testData.insightFiltersToApply);
    const reduxIdFilter = useSelector((state: RootState) => state.testData.selectedSurveyFilter);
    const reduxCompareIdFilter = useSelector((state: RootState) => state.testData.selectedCompareFilter);
    const surveyTitle = useSelector((state: RootState) => state.testData.surveyName);
    const selectedSurveyTitle = useSelector((state: RootState) => state.testData.selectedSurveyName);
    const clearFilters = useSelector((state: RootState) => state.testData.clearAllSelectedFilters);
    const countryFilter = useSelector((state: RootState) => state.testData.countryFilter);
    const ageFilter = useSelector((state: RootState) => state.testData.ageFilter);
    const genderFilter = useSelector((state: RootState) => state.testData.genderFilter);
    const educationFilter = useSelector((state: RootState) => state.testData.educationFilter);
    const householdFilter = useSelector((state: RootState) => state.testData.householdFilter);
    const [cmsData, setCmsData] = useState<IComparisonData | undefined>(undefined);
    const [isFilterExpanded, setIsFilterExpanded] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [surveyIdFilter, setSurveyIdFilter] = useState<IPositiveAttributeFilter>(
        newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
    );
    const [filters, setFilters] = useState<IPositiveAttributeFilter>(surveyIdFilter);
    const defaultDimensionsFilter = newPositiveAttributeFilter(Md.Dimension.attribute.displayForm, [
        "differentiation",
    ]);
    const [dimensionScoreComparisonFilter, setDimensionScoreComparisonFilter] =
        useState<IPositiveAttributeFilter>(defaultDimensionsFilter);
    const [sortedData, setSortedData] = useState([{}]);
    const [tableFilters, setTableFilters] = useState<IPositiveAttributeFilter>(
        positiveFilterInitialState.filter,
    );
    const topTenAwarenessDrillableItems = [HeaderPredicates.identifierMatch("label.vanswers.valuetext")];

    const { loading, error, data } = useQuery(query, {
        variables: {
            limit: 100,
        },
    });

    const onClick = () => {
        dispatch(setSelectedCompareFilter(null, null));
    };

    const getDrillFilters = (
        state: IHasNameUri | null,
        awareness: IHasNameUri | null,
    ): IPositiveAttributeFilter => {
        let filter1 = newPositiveAttributeFilter(Md.ValueText, []);

        if (state) {
            filter1 = newPositiveAttributeFilter(Md.ValueText, { uris: [state.uri] });
        }

        if (awareness) {
            filter1 = newPositiveAttributeFilter(Md.ValueText, { uris: [awareness.uri] });
        }

        return filter1;
    };

    function onAwarenesslDrill(drillTarget: any) {
        const { name, uri } =
            drillTarget.drillContext.element === "bar"
                ? drillTarget.drillContext.intersection[1].header.attributeHeaderItem
                : drillTarget.drillContext[0].intersection[1].header.attributeHeaderItem;

        const newAwareness: IHasNameUri = {
            name,
            uri,
        };

        setTableFilters(getDrillFilters(null, newAwareness));
    }

    const handleFilterSidebar = () => {
        setIsFilterExpanded(!isFilterExpanded);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchCustomerCms = async () => {
        const customerOverview = await getCustomerCms(data.allComparisons, userWorkspace || "No-data");
        setCmsData(customerOverview);
    };

    useEffect(() => {
        if (clearFilters) {
            setTableFilters(getDrillFilters(null, null));
            dispatch(clearAllSelectedFilters(false));
        }
    }, [clearFilters, dispatch]);

    useEffect(() => {
        if (data && userWorkspace) {
            fetchCustomerCms();
        }
    }, [data, fetchCustomerCms, userWorkspace]);

    useEffect(() => {
        if (cmsData) {
            sortCmsData(cmsData, (value: any) => setSortedData(value), sordedComparisonArr);
        }
    }, [cmsData]);

    useEffect(() => {
        if (filtersFromRedux && reduxIdFilter) {
            const newFilters = newPositiveAttributeFilter(filtersFromRedux.atrb, {
                uris: filtersFromRedux.ar,
            });
            const NewIdFilter = newPositiveAttributeFilter(reduxIdFilter.atrb, {
                uris: [reduxIdFilter.ar],
            });
            setSurveyIdFilter(NewIdFilter);
            setFilters(newFilters);
        }
    }, [filtersFromRedux, reduxIdFilter]);

    useEffect(() => {
        console.log({ filters });
    }, [filters]);

    if (loading)
        return (
            <div className={styles.LoadingDiv}>
                <CustomLoading label="...Loading" />
            </div>
        );

    if (!cmsData && !sortedData)
        return (
            <Page>
                <p>Something went wrong! check your internet connection!</p>
            </Page>
        );

    if (error)
        return (
            <Page>
                <p>Error in loading data! try re freshing the page!</p>
            </Page>
        );

    return (
        <Page showFilters={isFilterExpanded}>
            <div className={styless.ButtonsContainer}>
                <button className={btnStyle.ViewReportBtn} onClick={handleFilterSidebar}>
                    {isFilterExpanded ? "HIDE FILTERS" : "SHOW FILTERS"}
                </button>
                <button className={btnStyle.ViewReportBtn} onClick={onClick}>
                    VIEW REPORTS
                </button>
            </div>

            <div className={styless.SplitContainer}>
                {cmsData && (
                    <div className={styless.LeftContainer}>
                        <h2 style={{ color: "#000000" }}>
                            {selectedSurveyTitle ? selectedSurveyTitle : "...Loading"}
                        </h2>
                        <div className={styless.BigWidgetContainer}>
                            {cmsData.section1.map((item, index) => (
                                <div key={index} className={styless.SmallWidgetContainerCompare}>
                                    <p>{item.heading}</p>

                                    <InsightView
                                        insight={idRef(item.widgetId)}
                                        filters={[
                                            surveyIdFilter,
                                            filters,
                                            tableFilters,
                                            countryFilter,
                                            ageFilter,
                                            genderFilter,
                                            educationFilter,
                                            householdFilter,
                                        ]}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className={styless.DeepDiveSelectContainer}>
                            {cmsData.deepdiveComparison.map((item, index) => (
                                <div key={index} className={styless.SmallWidgetContainer}>
                                    <p>{item.heading}</p>

                                    <InsightView
                                        insight={idRef(item.widgetId)}
                                        filters={[
                                            surveyIdFilter,
                                            filters,
                                            tableFilters,
                                            countryFilter,
                                            ageFilter,
                                            genderFilter,
                                            educationFilter,
                                            householdFilter,
                                        ]}
                                    />
                                    <div className={styless.ReportButtonContainer}>
                                        <button
                                            className={
                                                selectedIndex === index
                                                    ? btnStyle.SelectedViewReportBtn
                                                    : btnStyle.ViewReportBtn
                                            }
                                            style={{ width: "100%", height: "50%" }}
                                            onClick={() => {
                                                setSelectedIndex(index);
                                                const deepDiveFitler = getDeepDiveFilter(item.heading);
                                                setDimensionScoreComparisonFilter(deepDiveFitler);
                                            }}
                                        >
                                            SELECT
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={styless.CustomWidgetContainer}>
                            <p>{cmsData.deepdiveContent[0].heading}</p>

                            <div className={styless.CustomWidget}>
                                <CustomWidget
                                    widgetId={cmsData.deepdiveContent[0].widgetId}
                                    filters={[
                                        surveyIdFilter,
                                        filters,
                                        dimensionScoreComparisonFilter,
                                        tableFilters,
                                        countryFilter,
                                        ageFilter,
                                        genderFilter,
                                        educationFilter,
                                        householdFilter,
                                    ]}
                                    setFilters={setFilters}
                                    setAnswerScale={(filter: any) => {
                                        setDimensionScoreComparisonFilter(
                                            newPositiveAttributeFilter("label.vanswers.answerscaledesc", {
                                                uris: [filter.uri],
                                            }),
                                        );
                                    }}
                                    answerScale={dimensionScoreComparisonFilter}
                                />
                            </div>
                        </div>

                        {cmsData.demographics.map((item) =>
                            item.widgets.map((widget, index) => (
                                <div key={index} className={styless.CustomWidgetContainer}>
                                    <p>{widget.heading}</p>
                                    <div className={styless.CustomWidget}>
                                        <CustomWidget
                                            widgetId={widget.widgetId}
                                            filters={[
                                                surveyIdFilter,
                                                filters,
                                                tableFilters,
                                                countryFilter,
                                                ageFilter,
                                                genderFilter,
                                                educationFilter,
                                                householdFilter,
                                            ]}
                                            setFilters={setFilters}
                                            setAnswerScale={(filter: any) =>
                                                setDimensionScoreComparisonFilter(
                                                    newPositiveAttributeFilter(
                                                        "label.vanswers.answerscaledesc",
                                                        {
                                                            uris: [filter.uri],
                                                        },
                                                    ),
                                                )
                                            }
                                            answerScale={dimensionScoreComparisonFilter}
                                        />
                                    </div>
                                </div>
                            )),
                        )}

                        <div className={styless.KPIRowContainer}>
                            {cmsData.kpis.map((item) =>
                                item.widgets.map((widget, index) => (
                                    <div key={index} className={styless.SmallWidgetContainer}>
                                        <p>{widget.heading}</p>

                                        <InsightView
                                            insight={idRef(widget.widgetId)}
                                            filters={[
                                                surveyIdFilter,
                                                filters,
                                                tableFilters,
                                                countryFilter,
                                                ageFilter,
                                                genderFilter,
                                                educationFilter,
                                                householdFilter,
                                            ]}
                                        />
                                    </div>
                                )),
                            )}
                        </div>
                        <div className={styless.DeepDiveComparisonContainer}>
                            {cmsData.top10Awareness.map((item) =>
                                item.widgets.map((widget, index) => (
                                    <div key={index}>
                                        <p>{widget.heading}</p>
                                        <div style={{ height: 300, minWidth: 550 }}>
                                            <InsightView
                                                insight={idRef(widget.widgetId)}
                                                filters={[
                                                    surveyIdFilter,
                                                    filters,
                                                    tableFilters,
                                                    countryFilter,
                                                    ageFilter,
                                                    genderFilter,
                                                    educationFilter,
                                                    householdFilter,
                                                ]}
                                                drillableItems={topTenAwarenessDrillableItems}
                                                onDrill={onAwarenesslDrill}
                                            />
                                        </div>
                                    </div>
                                )),
                            )}
                        </div>
                    </div>
                )}

                <div className={styless.RightContainer}>
                    {cmsData && reduxCompareIdFilter ? (
                        <div className={styless.ComparisonConatinerRight}>
                            <h2 style={{ color: "#000000" }}>{surveyTitle ? surveyTitle : "...Loading"}</h2>
                            <ComparedPage
                                sortedData={sortedData}
                                cmsData={cmsData}
                                error={error}
                                loading={loading}
                                reduxFilter={reduxCompareIdFilter}
                            />
                        </div>
                    ) : (
                        <div
                            className={styless.ComparisonConatinerRight}
                            style={{ backgroundColor: "#f4f0f0" }}
                        >
                            <h2 style={{ color: "#000000" }}>{"Select comparison"}</h2>
                            <SurveyList isComparison={true} />
                        </div>
                    )}
                </div>
            </div>
        </Page>
    );
};

export default ComparisonForm;
