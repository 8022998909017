import { IOverviewCmsData } from "../utils/CmsValidators";
import { IDeepDiveCmsData } from "../utils/CmsValidators";
import { IDifferentiationCmsData } from "../utils/CmsValidators";
import { IKeyIndicatorCmsData } from "../utils/CmsValidators";
import { IDemographicsCmsData } from "../../routes/Demographics";

export const getCustomerCms = (data: any, workspace: string): any | undefined => {
    const overviewFound:
        | IOverviewCmsData
        | IDifferentiationCmsData
        | IKeyIndicatorCmsData
        | IDemographicsCmsData
        | IDeepDiveCmsData
        | undefined = data.find((overview: any) => overview.customer === workspace);

    if (overviewFound) {
        return overviewFound;
    }

    const overviewDefault:
        | IOverviewCmsData
        | IDifferentiationCmsData
        | IKeyIndicatorCmsData
        | IDemographicsCmsData
        | IDeepDiveCmsData
        | undefined = data.find((overview: any) => overview.customer === "Default");
    return overviewDefault;
};
