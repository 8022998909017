export const DEMOGRAPHIC_QUERY = (query: string): string => {
    return `query Demographic { 
        ${query} {
            title
            customer
            description
            demographics {
                ... on WidgetHeadingDescriptionRecord {
                    heading
                    description
                    widgetId
                }
            }
        }
    }`;
};
