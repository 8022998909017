import React from "react";
import DemographicsForm from "../components/Form/DemographicsForm";

export interface IDemographicsCmsData {
    title: string;
    customer: string;
    description: string;
    demographics: [
        {
            heading: string;
            description: string;
            digetId: string;
        },
    ];
}

const Demographics: React.FC = () => {
    return <DemographicsForm />;
};

export default Demographics;
