import React from "react";
//import { Link } from "react-router-dom";

import styles from "../routes/SurveyListStyle.module.scss";

export interface SurveyListCardProps extends React.HTMLProps<HTMLCollection> {
    label?: string;
    onClick?: () => void;
}

const SurveyListCard: React.FC<SurveyListCardProps> = ({ onClick, label }) => {
    return (
        <div className={styles.SurveyCard}>
            <h2>{label}</h2>
            {/* <Link
                to="/overview"
                style={{
                    textDecoration: "none",
                }}
            > */}
            <button onClick={onClick} className={styles.ViewReportBtn}>
                VIEW REPORT
            </button>
            {/* </Link> */}
        </div>
    );
};

export default SurveyListCard;
