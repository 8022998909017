import React, { useState } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";

import { WorkspaceProvider } from "../contexts/Workspace";
import Page from "../components/Page";

import Login from "./Login";
import Logout from "./Logout";
import Overview from "./Overview";
import ImagePerception from "./ImagePerception";
import Differentiation from "./Differentiation";
import SocialResponsibility from "./SocialResponsibility";
import Segmentation from "./Segmentation";
import KeyIndicators from "./KeyIndicators";
import Comparison from "./Comparison";

import styles from "./AppRouter.module.scss";

import { useAuth } from "../contexts/Auth";
import { AuthStatus } from "../contexts/Auth/state";
import FirebaseLoginForm from "../components/Auth/FirebaseLoginForm";
import Demographics from "./Demographics";
import SurveyList from "./SurveyList";
import Introduction from "../components/Introduction/Introduction";

const RedirectIfNotLoggedIn: React.FC = () => {
    const auth = useAuth();
    const shouldRedirectToLogin = auth.authStatus === AuthStatus.UNAUTHORIZED;
    return shouldRedirectToLogin ? <Route component={() => <Redirect to="/login" />} /> : null;
};

const ShowIntroduction: React.FC = () => {
    const auth = useAuth();
    const isUserAuthed = auth.authStatus === AuthStatus.AUTHORIZED;
    const [cookies, setCookies] = useCookies(["introduction"]);
    const [showIntroduction, setShowIntroduction] = useState(cookies.introduction === "true" ? true : false);

    return showIntroduction && isUserAuthed ? (
        <Introduction
            callback={() => {
                setShowIntroduction(false);
                setCookies("introduction", false);
            }}
        />
    ) : null;
};

const AppRouter: React.FC = () => {
    return (
        <div className={styles.AppRouter}>
            <Router>
                {/* WorkspaceProvider depends on Router so it must be nested */}
                <WorkspaceProvider>
                    <>
                        <ShowIntroduction />
                        <Route exact path="/" component={SurveyList} />
                        <Route exact path="/overview" component={Overview} />

                        <Route exact path="/dashboard" component={() => <Page>Dashboard</Page>} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/logout" component={Logout} />

                        {/* <Route exact path="/deep-dive" component={DeepDive} /> */}
                        <Route exact path="/differentiation" component={Differentiation} />
                        <Route exact path="/image-perception" component={ImagePerception} />
                        <Route exact path="/social-responsibility" component={SocialResponsibility} />
                        <Route exact path="/segmentation" component={Segmentation} />
                        <Route exact path="/key-indicators" component={KeyIndicators} />
                        <Route exact path="/demographics" component={Demographics} />
                        <Route exact path="/comparison" component={Comparison} />

                        <Route exact path="/firebaseloginform" component={FirebaseLoginForm} />

                        <RedirectIfNotLoggedIn />
                    </>
                </WorkspaceProvider>
            </Router>
        </div>
    );
};

export default AppRouter;
