import React, { useEffect, useState } from "react";
import { InsightView } from "@gooddata/sdk-ui-ext";
import { useQuery } from "graphql-hooks";
import { idRef } from "@gooddata/sdk-model";
import { newPositiveAttributeFilter, IPositiveAttributeFilter } from "@gooddata/sdk-model";
import { useSelector } from "react-redux";

import { KEY_INDICATOR_QUERY } from "../Querys/KeyIndicatorQuery";
import Page from "../Page";
import { RootState } from "../../redux/store";
import { getCustomerCms } from "./query-validator";
import styles from "./Form.module.scss";
import { IKeyIndicatorCmsData } from "../utils/CmsValidators";
import CustomLoading from "../CustomLoading";
import * as Md from "../../md/full";
import ErrorView from "../ErrorView";

const KeyIndicatorsForm: React.FC = () => {
    const query = KEY_INDICATOR_QUERY("allKeyIndicators");
    const filtersFromRedux = useSelector((state: RootState) => state.testData.insightFiltersToApply);
    const reduxIdFilter = useSelector((state: RootState) => state.testData.selectedSurveyFilter);
    const countryFilter = useSelector((state: RootState) => state.testData.countryFilter);
    const ageFilter = useSelector((state: RootState) => state.testData.ageFilter);
    const genderFilter = useSelector((state: RootState) => state.testData.genderFilter);
    const educationFilter = useSelector((state: RootState) => state.testData.educationFilter);
    const householdFilter = useSelector((state: RootState) => state.testData.householdFilter);
    const [surveyIdFilter, setSurveyIdFilter] = useState<IPositiveAttributeFilter>(
        newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
    );
    const [filters, setFilters] = useState<IPositiveAttributeFilter>(reduxIdFilter);
    const [cmsData, setCmsData] = useState<IKeyIndicatorCmsData | undefined>();
    const userWorkspace = useSelector(
        (state: RootState) => state.testData.currentUserSettings?.workspaces[0],
    );

    const { loading, error, data } = useQuery(query);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchCustomerCms = async () => {
        const customerOverview = await getCustomerCms(data.allKeyIndicators, userWorkspace || "No-data");
        setCmsData(customerOverview);
    };

    useEffect(() => {
        if (data && userWorkspace) {
            fetchCustomerCms();
        }
    }, [data, fetchCustomerCms, userWorkspace]);

    useEffect(() => {
        if (filtersFromRedux && reduxIdFilter) {
            const newFilters = newPositiveAttributeFilter(filtersFromRedux.atrb, {
                uris: filtersFromRedux.ar,
            });
            const NewIdFilter = newPositiveAttributeFilter(reduxIdFilter.atrb, {
                uris: [reduxIdFilter.ar],
            });
            setSurveyIdFilter(NewIdFilter);
            setFilters(newFilters);
        }
    }, [filtersFromRedux, reduxIdFilter]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page showFilters={true}>
            <div style={{ height: "100%" }}>
                {cmsData ? (
                    <div style={{ display: "grid", gap: 32 }}>
                        {cmsData.keyIndicators.map((indicator: any, index: number) => (
                            <div key={index}>
                                <div className={styles.KeyIndicatorsContainer}>
                                    <h2 style={{ color: "#000000", top: 0 }}>{indicator.title}</h2>
                                    {indicator.content.map((item: any, index: number) => {
                                        if (index === 0) {
                                            return (
                                                <div key={index} className={styles.InsightOneTwo}>
                                                    <h3 style={{ alignItems: "center" }}>{item.heading}</h3>
                                                    <p style={{ alignItems: "center" }}>{item.description}</p>
                                                    <InsightView
                                                        key={item.uniqueId}
                                                        insight={idRef(item.widgetId)}
                                                        filters={[
                                                            surveyIdFilter,
                                                            countryFilter,
                                                            ageFilter,
                                                            genderFilter,
                                                            educationFilter,
                                                            householdFilter,
                                                            filters,
                                                        ]}
                                                    />
                                                </div>
                                            );
                                        } else if (index === 1) {
                                            return (
                                                <div key={index} className={styles.InsightTwo}>
                                                    <h3 style={{ alignItems: "center" }}>{item.heading}</h3>
                                                    <p style={{ alignItems: "center" }}>{item.description}</p>
                                                    <InsightView
                                                        key={item.uniqueId}
                                                        insight={idRef(item.widgetId)}
                                                        filters={[
                                                            surveyIdFilter,
                                                            countryFilter,
                                                            ageFilter,
                                                            genderFilter,
                                                            educationFilter,
                                                            householdFilter,
                                                            filters,
                                                        ]}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={index} className={styles.LongInsight}>
                                                    <h3 style={{ alignItems: "center" }}>{item.heading}</h3>
                                                    <p style={{ alignItems: "center" }}>{item.description}</p>
                                                    <InsightView
                                                        key={item.uniqueId}
                                                        insight={idRef(item.widgetId)}
                                                        filters={[
                                                            surveyIdFilter,
                                                            countryFilter,
                                                            ageFilter,
                                                            genderFilter,
                                                            educationFilter,
                                                            householdFilter,
                                                            filters,
                                                        ]}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : error ? (
                    <ErrorView />
                ) : loading ? (
                    <div className={styles.LoadingDiv}>
                        <CustomLoading label="...Loading" />
                    </div>
                ) : (
                    <ErrorView />
                )}
            </div>
        </Page>
    );
};

export default KeyIndicatorsForm;
