import React, { useState } from "react";
import { useSelector } from "react-redux";
//import throttle from "lodash/throttle";

import { useAuth } from "../../contexts/Auth";
import { AuthStatus } from "../../contexts/Auth/state";
//import WorkspacePicker from "../controls/WorkspacePicker";
import { RootState } from "../../redux/store";
import Logo from "./Logo";
import Aside from "./Aside";

import styles from "./Header.module.scss";
import Sidebar from "../Sidebar/Sidebar";
import FilterComponent from "../filters/FilterComponent";

interface IHeader {
    isTooSmall: boolean | 0 | null;
    showSidebars?: boolean | 0 | null;
    callback: (value: boolean) => void;
    showFilters?: boolean;
}

interface IBurger {
    callback: (value: boolean) => void;
}

// a wrapper for items visible to authenticated users only (e.g. WorkspacePicker)
const ProtectedItems: React.FC = ({ children }) => {
    const { authStatus } = useAuth();
    if (authStatus !== AuthStatus.AUTHORIZED) return null;
    return <>{children || null}</>;
};

const BurgerMenu: React.FC<IBurger> = ({ callback }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.BurgerMenu}>
            <button
                className={styles.BurgerToggle}
                onClick={() => {
                    setIsOpen(!isOpen);
                    callback(!isOpen);
                }}
            >
                ☰
            </button>
            {isOpen && (
                <>
                    <Sidebar />

                    <ProtectedItems>
                        {/* Uncomment these lines to add a workspace picker into the burger menu */}
                        {/* <div className={styles.Centered}>
                            <WorkspacePicker />
                        </div> */}
                    </ProtectedItems>
                </>
            )}
        </div>
    );
};

const Header: React.FC<IHeader> = ({ isTooSmall, callback, showSidebars = true, showFilters }) => {
    const userData = useSelector((state: RootState) => state.testData.currentUserSettings);

    return (
        <nav className={styles.Header}>
            {isTooSmall ? (
                <>
                    <BurgerMenu callback={(value: boolean) => callback(value)} />
                    <Logo />
                </>
            ) : (
                <>
                    {/* <ProtectedItems>
                        <div className={`${styles.Centered} ${styles.HeaderLinks}`}>
                            <WorkspacePicker />
                        </div>
                    </ProtectedItems> */}

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Logo />
                        {!!userData && <h2 className={styles.WelcomeMessage}>Welcome {userData.fullName}</h2>}
                    </div>

                    {showSidebars && <Sidebar />}
                    {showFilters && showSidebars && <FilterComponent />}
                </>
            )}
            <Aside />
        </nav>
    );
};

export default Header;
