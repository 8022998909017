export const DEEP_DIVE_QUERY = (query: string): string => {
    return `query { 
        ${query} {
            title
            customer
            questionList {
                heading
                description
                widgetId
            }
            pieChart {
                heading
                description
                widgetId
            }
            gender {
                heading
                description
                widgetId
            }
            age {
                heading
                description
                widgetId
            }
            income {
                heading
                description
                widgetId
            }
            education {
                heading
                description
                widgetId
            }
            comparison {
                heading
                description
                widgets {
                    ... on HeadingWidgetRecord {
                        widgetId
                        heading
                    }
                }
            }
        }
    }`;
};
