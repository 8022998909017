import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AttributeElements } from "@gooddata/sdk-ui-filters";
import { attributeDisplayFormRef, IAttribute } from "@gooddata/sdk-model";
import { InsightView } from "@gooddata/sdk-ui-ext";
import { useSelector, useDispatch } from "react-redux";
import { IPositiveAttributeFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";

import styles from "./Filter.module.scss";
import DropdownComponent from "./DropdownComponent";
import { RootState } from "../../redux/store";
import {
    clearAllSelectedFilters,
    setAgeFilter,
    setCountryFilter,
    setEducationFilter,
    setGenderFilter,
    setHouseholdFilter,
} from "../../redux/actions/testActions";
import * as Md from "../../md/full";

interface IFilterElement {
    attributeID: IAttribute;
    title: string;
    setArray: Dispatch<SetStateAction<string[]>>;
}

const FilterElement: React.FC<IFilterElement> = ({ attributeID, title, setArray }) => {
    return (
        <AttributeElements displayForm={attributeDisplayFormRef(attributeID)} limit={30}>
            {({ validElements, error }) => {
                if (error) {
                    return <div>{error}</div>;
                }

                return (
                    <div style={{ marginBottom: 32 }} className={styles.FilterCategory}>
                        {validElements ? (
                            <>
                                <DropdownComponent
                                    attributeId={attributeID}
                                    title={title}
                                    item={validElements.items}
                                    multiselect={true}
                                    setArray={setArray}
                                />
                            </>
                        ) : null}
                    </div>
                );
            }}
        </AttributeElements>
    );
};

const FilterComponent: React.FC = () => {
    const isExpanded = true;
    const dispatch = useDispatch();
    const [surveyIdFilter, setSurveyIdFilter] = useState<IPositiveAttributeFilter>(
        newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
    );
    const filtersFromRedux = useSelector((state: RootState) => state.testData.insightFiltersToApply);
    const reduxIdFilter = useSelector((state: RootState) => state.testData.selectedSurveyFilter);
    const countryFilter = useSelector((state: RootState) => state.testData.countryFilter);
    const ageFilter = useSelector((state: RootState) => state.testData.ageFilter);
    const genderFilter = useSelector((state: RootState) => state.testData.genderFilter);
    const educationFilter = useSelector((state: RootState) => state.testData.educationFilter);
    const householdFilter = useSelector((state: RootState) => state.testData.householdFilter);
    const [filterArray, setFilterArray] = useState<string[]>([]);
    const [countryArray, setCountryArray] = useState<string[]>([]);
    const [genderArray, setGenderArray] = useState<string[]>([]);
    const [educationArray, setEducationArray] = useState<string[]>([]);
    const [incomeArray, setIncomeArray] = useState<string[]>([]);

    const clearAllFilters = () => {
        dispatch(clearAllSelectedFilters(true));
    };

    useEffect(() => {
        if (filtersFromRedux && reduxIdFilter) {
            const NewIdFilter = newPositiveAttributeFilter(reduxIdFilter.atrb, {
                uris: [reduxIdFilter.ar],
            });

            setSurveyIdFilter(NewIdFilter);
        }
    }, [filtersFromRedux, reduxIdFilter]);

    useEffect(() => {
        dispatch(setAgeFilter(Md.Age.Interval, filterArray));
        dispatch(setCountryFilter(Md.CountryCode, countryArray));
        dispatch(setGenderFilter(Md.Gender.Default, genderArray));
        dispatch(setEducationFilter(Md.Education.Level, educationArray));
        dispatch(setHouseholdFilter(Md.HouseholdIncome.IncomeInterval, incomeArray));
    }, [countryArray, filterArray, genderArray, educationArray, incomeArray, dispatch]);

    return (
        <div className={styles.FilterCard}>
            {isExpanded && (
                <div>
                    <div className={styles.FilteredResult}>
                        <p>Filtered result</p>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 100,
                                width: 150,
                                padding: 16,
                            }}
                        >
                            <InsightView
                                insight={Md.Insights.ResponsesFILTERED}
                                filters={[
                                    surveyIdFilter,
                                    ageFilter,
                                    countryFilter,
                                    genderFilter,
                                    educationFilter,
                                    householdFilter,
                                ]}
                            />
                        </div>
                    </div>
                    <div className={styles.FilterOptionContainer}>
                        <div className={styles.FilterDropdown}>
                            <p className={styles.FilterTitle}>Filters</p>
                            <button className={styles.DropdownBtn} onClick={clearAllFilters}>
                                Clear all
                            </button>
                        </div>

                        <FilterElement
                            attributeID={Md.CountryCode}
                            title="Country"
                            setArray={setCountryArray}
                        />
                        <FilterElement attributeID={Md.Age.Interval} title="Age" setArray={setFilterArray} />

                        <FilterElement
                            attributeID={Md.Gender.Default}
                            title="Gender"
                            setArray={setGenderArray}
                        />
                        <FilterElement
                            attributeID={Md.Education.Level}
                            title="Education level"
                            setArray={setEducationArray}
                        />
                        <FilterElement
                            attributeID={Md.HouseholdIncome.IncomeInterval}
                            title="Income Level"
                            setArray={setIncomeArray}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterComponent;
