export const OVERVIEW_QUERY = (query: string): string => {
    return `query  {
        ${query} { 
            customer
            introText
            title
            totalScore {
                widgetId
                heading
                description
            }
            totalScoreComparison {
                heading
                description
                widgets {
                    ... on HeadingWidgetRecord {
                        widgetId
                        heading
                    }
                }
            }
            dimensionWeight {
                heading
                description
                widgets {
                    ... on HeadingWidgetRecord {
                        widgetId
                        heading
                    }
                }
            }
            dimensionScore {
                heading
                description
                widgets {
                    ... on HeadingWidgetRecord {
                        widgetId
                        heading
                    }
                }
            }
            dimensionScoreComparison {
                heading
                description
                widgets {
                    ... on HeadingWidgetRecord {
                        widgetId
                        heading
                    }
                }
            }
            trustAndLoyalty {
                heading
                description
                widgets {
                    ... on HeadingWidgetRecord {
                        widgetId
                        heading
                    }
                }
            }
            topTenAwareness {
                heading
                description
                widgets {
                    ... on HeadingWidgetRecord {
                        widgetId
                        heading
                    }
                }
            }
            introOverlay {
                heading
                description
                image {
                    url
                  }
            }
         }
      }`;
};
