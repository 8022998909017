import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import styles from "./Header.module.scss";
import ArticleIcon from "../../assets/ArticleIcon.svg.js";
import BubbleChartIcon from "../../assets/BubbleChartIcon.svg.js";
import PieChartIcon from "../../assets/PieChartIcon.svg.js";
import ScaleIcon from "../../assets/ScaleIcon.svg.js";
import DifferentiationIcon from "../../assets/Differentiation.svg.js";
import SegmentationIcon from "../../assets/SegmentationIcon.svg.js";
import ImageAndPerceptionIcon from "../../assets/ImageAndPerceptionIcon.svg.js";
import SustainabilityIcon from "../../assets/SustainabilityIcon.svg.js";

const Links: React.FC = () => {
    const LINK_DATA = [
        {
            to: "/overview",
            name: "Overview",
            icon: <ArticleIcon />,
        },
        {
            to: "/key-indicators",
            name: "Key Indicators",
            icon: <BubbleChartIcon />,
        },
        {
            to: "/demographics",
            name: "Demographics",
            icon: <PieChartIcon />,
        },
        {
            to: "/comparison",
            name: "Compare report",
            icon: <ScaleIcon />,
        },
    ];

    const DEEP_DIVE_LINK_DATA = [
        {
            to: "/differentiation",
            name: "Differentiation",
            class: cx(styles.HeaderLinks, "s-welcome-link"),
            exact: false,
            icon: <DifferentiationIcon />,
        },
        {
            to: "/segmentation",
            name: "Segmentation",
            exact: true,
            icon: <SegmentationIcon />,
        },
        {
            to: "/image-perception",
            name: "Image & Perception",
            exact: true,
            icon: <ImageAndPerceptionIcon />,
        },
        {
            to: "/social-responsibility",
            name: "Social Responsibility\n& Sustainability",
            exact: true,
            icon: <SustainabilityIcon />,
        },
    ];

    return (
        <>
            <div className={styles.spacer} />
            {LINK_DATA.map(({ to, name, icon }, i) => (
                <NavLink
                    key={i}
                    to={to}
                    className={styles.HeaderLinks}
                    activeClassName={styles.HeaderLinksActive}
                >
                    {icon}
                    <div className={styles.LeftSideLink}>{name}</div>
                </NavLink>
            ))}

            <p className={styles.CategoryTitle}>Deep dive</p>

            {DEEP_DIVE_LINK_DATA.map((item, i) => (
                <NavLink
                    key={i}
                    to={item.to}
                    className={item.class || styles.HeaderLinks}
                    activeClassName={styles.HeaderLinksActive}
                    exact={item.exact}
                >
                    <div>{item.icon}</div>
                    <div className={styles.LeftSideLink}>{item.name}</div>
                </NavLink>
            ))}

            <div className={styles.spacer} />
            <div className={styles.spacer} />
        </>
    );
};

export default Links;
