import { FC, useState, useContext, useEffect } from "react";

import styles from "./FirebaseLoginForm.module.scss";

import { FirebaseAuthContext } from "../../contexts/FirebaseAuth/FirebaseAuthContext";
import Header from "../Header/Header";

type FirebaseUser = {
    email: string;
    password: string;
};

const initialUserState: FirebaseUser = {
    email: "test@live.se",
    password: "123456789",
};

export const FirebaseLoginForm: FC = () => {
    const fbContext = useContext(FirebaseAuthContext);

    const [disabled, setDisabled] = useState(false);
    const [userState, setUserState] = useState<FirebaseUser>(initialUserState);
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);

    const headertosmall = true;

    const onInputChange = (field: string, value: any) => {
        setUserState({
            ...userState,
            [field]: value,
        });
    };

    const loginUserToFB = (email: string, password: string) => {
        fbContext?.login(email, password);
    };

    useEffect(() => {
        setDisabled(userState.email.length === 0 || userState.password.length === 0);
    }, [userState.email, userState.password]);

    return (
        <div className={isBurgerOpen ? styles.page : styles.page}>
            <Header isTooSmall={headertosmall} callback={(value: boolean) => setIsBurgerOpen(value)}></Header>

            <div className={styles.card}>
                <h1>Firebase Login</h1>

                <input
                    className={styles.input}
                    placeholder={initialUserState.email}
                    type="text"
                    value={userState.email}
                    onChange={(input) => onInputChange("username", input.target.value)}
                />

                <input
                    className={styles.input}
                    placeholder={initialUserState.password}
                    type="text"
                    value={userState.password}
                    onChange={(input) => onInputChange("password", input.target.value)}
                />

                <button
                    className={disabled ? styles.loginBtn : styles.disabeledLoginBtn}
                    disabled={disabled}
                    onClick={() => loginUserToFB(userState.email, userState.password)}
                >
                    Login
                </button>
            </div>
        </div>
    );
};

export default FirebaseLoginForm;
