import {
    TEST_GET_DATA,
    SET_CURRENT_USER_DATA,
    SET_USER_WORKSPACE,
    CLEAR_REDUX,
    INSIGHT_FILTERS_TO_APPLY,
    SET_SELECTED_SURVEY_FILTER,
    CLEAR_ALL_SELECTED_FILTERS,
    COMPARE_REPORT_FILTER,
    SURVEY_NAME,
    SELECTED_SURVEY_NAME,
    SET_COUNTRY_FILTER,
    SET_AGE_FILTER,
    SET_GENDER_FILTER,
    SET_EDUCATION_FILTER,
    SET_HOUSEHOLD_FILTER,
} from "../actions/testActions";

const initialState = {
    testData: null,
    currentUserSettings: null,
    insightFiltersToApply: null,
    selectedSurveyFilter: null,
    clearAllSelectedFilters: null,
    selectedCompareFilter: null,
    surveyName: null,
    selectedSurveyName: null,
    countryFilter: null,
    ageFilter: null,
    genderFilter: null,
    educationFilter: null,
    householdFilter: null,
};

export default function Auth(state = initialState, action: any) {
    switch (action.type) {
        case TEST_GET_DATA:
            return {
                ...state,
                testData: action.payload,
            };

        case SET_CURRENT_USER_DATA:
            return {
                ...state,
                currentUserSettings: action.payload,
            };

        case SET_USER_WORKSPACE:
            return {
                ...state,
                workspace: action.payload,
            };

        case INSIGHT_FILTERS_TO_APPLY:
            return {
                ...state,
                insightFiltersToApply: action.payload,
            };
        case SET_COUNTRY_FILTER:
            return {
                ...state,
                countryFilter: action.payload,
            };
        case SET_AGE_FILTER:
            return {
                ...state,
                ageFilter: action.payload,
            };

        case SET_GENDER_FILTER:
            return {
                ...state,
                genderFilter: action.payload,
            };

        case SET_EDUCATION_FILTER:
            return {
                ...state,
                educationFilter: action.payload,
            };

        case SET_HOUSEHOLD_FILTER:
            return {
                ...state,
                householdFilter: action.payload,
            };

        case SET_SELECTED_SURVEY_FILTER:
            return {
                ...state,
                selectedSurveyFilter: action.payload,
            };
        case COMPARE_REPORT_FILTER:
            return {
                ...state,
                selectedCompareFilter: action.payload,
            };
        case CLEAR_ALL_SELECTED_FILTERS:
            return {
                ...state,
                clearAllSelectedFilters: action.payload,
            };

        case SURVEY_NAME:
            return {
                ...state,
                surveyName: action.payload,
            };

        case CLEAR_REDUX:
            return initialState;

        case SELECTED_SURVEY_NAME:
            return {
                ...state,
                selectedSurveyName: action.payload,
            };
    }

    return state;
}
