import { initializeApp } from "firebase/app";

import {
    getAuth,
    signInWithEmailAndPassword,
    UserCredential,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
} from "firebase/auth";

import { getDoc, doc, getFirestore, setDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDptXDpvv6Fora7idxcPvtvmRCzz7eVVac",
    authDomain: "brandr-web.firebaseapp.com",
    projectId: "brandr-web",
    storageBucket: "brandr-web.appspot.com",
    messagingSenderId: "364161805375",
    appId: "1:364161805375:web:51c2388e1d8fc7b07d1c8a",
    measurementId: "G-4XRFEPPLJ9",
};

export const fbInit = () => {
    initializeApp(firebaseConfig);
};

export const createUserToFB = async (email: string, password: string): Promise<any> => {
    const auth = getAuth();

    const response = await createUserWithEmailAndPassword(auth, email, password)
        .then((r) => {
            return r.user;
        })
        .catch((error) => {
            return String(error.code);
        });

    return response;
};

export const loginToFirebase = async (
    email: string,
    password: string,
): Promise<UserCredential | undefined> => {
    const auth = getAuth();

    try {
        const credentialUser = await signInWithEmailAndPassword(auth, email, password);
        return credentialUser;
    } catch (error) {
        return undefined;
    }
};

export const getCurrentUserFromFB = async () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            //return user
        } else {
            //return undefined
        }
    });
};

export const addUserToFS = (id: string, obj: any) => {
    const db = getFirestore();
    setDoc(doc(db, "users", String(id)), {
        array: obj,
    });
};

export const getUserFromFS = async (usersID: string) => {
    const db = getFirestore();
    const docRef = doc(db, "users", String(usersID));
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return true;
    } else {
        return false;
    }
};

export const signOutUser = async () => {
    const auth = getAuth();
    return await auth.signOut();
};
