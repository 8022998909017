import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import testReducer from "./reducers/testReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import Auth from "./reducers/testReducer";

const persistConfig = {
    key: "root",
    storage: storage,
    testData: Auth,
};

const rootReducer = combineReducers({
    testData: testReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk);

const store = createStore(persistedReducer, middleware);

const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export { persistor, store };
