import { idRef, IPositiveAttributeFilter, newPositiveAttributeFilter, IFilter } from "@gooddata/sdk-model";
import { useState, useEffect, FC } from "react";
import { InsightView } from "@gooddata/sdk-ui-ext";
import { useQuery } from "graphql-hooks";
import { useSelector, useDispatch } from "react-redux";
import { useInsightDataView } from "@gooddata/sdk-ui";
import styled from "styled-components";

import Page from "../Page";
import { DIFFERENTIATION_QUERY } from "../Querys/DifferentiationQuery";
import { RootState } from "../../redux/store";
import { IDifferentiationCmsData } from "../utils/CmsValidators";
import { IHasNameUri } from "./OverviewForm";
import styles from "./Form.module.scss";
import { pieChartDrillableItems, sortedDeepDiveArr } from "../utils/DeepDiveValidators";
import { sortCmsData } from "../utils/DeepDiveValidators";
import { fetchAnswerScaleFilters } from "../utils/DeepDiveValidators";
import * as Md from "../../md/full";
import CustomLoading from "../CustomLoading";
import BarChart from "../customWidget/BarChart";
import { getCustomerCms } from "./query-validator";
import { clearAllSelectedFilters } from "../../redux/actions/testActions";
import ErrorView from "../ErrorView";

interface ICustomWidget {
    widgetId: string;
    filters: IFilter[];
    setFilters: any;
    setAnswerScale: any;
    answerScale?: any;
    heading?: string;
}

export const CustomWidget: FC<ICustomWidget> = ({
    filters,
    setFilters,
    setAnswerScale,
    answerScale,
    widgetId,
    heading,
}) => {
    const { result: barChartResult, status: barChartStatus } = useInsightDataView(
        { insight: idRef(widgetId) },
        [],
    );

    const barChartDefinition = barChartResult?.definition;

    return (
        <div style={{ height: "100%", width: "100%", padding: 16 }}>
            {barChartStatus === "success" ? (
                <BarChart
                    heading={heading}
                    widgetId={widgetId}
                    {...barChartDefinition}
                    setFilters={setFilters}
                    setAnswerScale={setAnswerScale}
                    answerScale={answerScale}
                    filters={filters}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export const DifferentiationForm: React.FC = () => {
    const dispatch = useDispatch();
    const query = DIFFERENTIATION_QUERY("allDifferentiations");
    const userWorkspace = useSelector(
        (state: RootState) => state.testData.currentUserSettings?.workspaces[0],
    );
    const filtersFromRedux = useSelector((state: RootState) => state.testData.insightFiltersToApply);
    const reduxIdFilter = useSelector((state: RootState) => state.testData.selectedSurveyFilter);
    const clearFilters = useSelector((state: RootState) => state.testData.clearAllSelectedFilters);
    const countryFilter = useSelector((state: RootState) => state.testData.countryFilter);
    const ageFilter = useSelector((state: RootState) => state.testData.ageFilter);
    const genderFilter = useSelector((state: RootState) => state.testData.genderFilter);
    const educationFilter = useSelector((state: RootState) => state.testData.educationFilter);
    const householdFilter = useSelector((state: RootState) => state.testData.householdFilter);
    const differentiationFilter = newPositiveAttributeFilter(Md.Dimension.attribute.displayForm, [
        "differentiation",
    ]);

    const [cmsData, setCmsData] = useState<IDifferentiationCmsData | undefined>();
    const [answerscale, setAnswerScale] = useState<IHasNameUri | null>(null);
    const [filters, setFilters] = useState<IPositiveAttributeFilter>(reduxIdFilter);
    const [surveyIdFilter, setSurveyIdFilter] = useState<IPositiveAttributeFilter>(
        newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
    );
    const [sortedData, setSortedData] = useState([{}]);

    const { loading, error, data } = useQuery(query);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchCustomerCms = async () => {
        const customerOverview = await getCustomerCms(data.allDifferentiations, userWorkspace || "No-data");
        setCmsData(customerOverview);
    };
    function onAnswerScaleDrill(drillTarget: any) {
        const { name, uri } =
            drillTarget.drillContext.element === "slice"
                ? drillTarget.drillContext.intersection[1].header.attributeHeaderItem
                : drillTarget.drillContext[0].intersection[1].header.attributeHeaderItem;

        const newAnswerScale: IHasNameUri = {
            name,
            uri,
        };

        setAnswerScale(newAnswerScale);
    }

    useEffect(() => {
        if (data && userWorkspace) {
            fetchCustomerCms();
        }
    }, [data, fetchCustomerCms, userWorkspace]);

    useEffect(() => {
        if (cmsData) {
            sortCmsData(cmsData, (value: any) => setSortedData(value), sortedDeepDiveArr);
        }
    }, [cmsData]);

    useEffect(() => {
        if (filtersFromRedux && reduxIdFilter) {
            const newFilters = newPositiveAttributeFilter(filtersFromRedux.atrb, {
                uris: filtersFromRedux.ar,
            });
            const NewIdFilter = newPositiveAttributeFilter(reduxIdFilter.atrb, {
                uris: [reduxIdFilter.ar],
            });
            setSurveyIdFilter(NewIdFilter);
            setFilters(newFilters);
        }
    }, [filtersFromRedux, reduxIdFilter]);

    useEffect(() => {
        if (clearFilters) {
            setAnswerScale(null);
            dispatch(clearAllSelectedFilters(false));
        }
    }, [clearFilters, dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const answerScaleFilter = fetchAnswerScaleFilters(answerscale);

    useEffect(() => {
        console.log({ answerScaleFilter });
    }, [answerScaleFilter]);

    return (
        <Page showFilters={true}>
            {sortedData && cmsData ? (
                <Container>
                    <ComparisonContainer>
                        <ComparisonGrid>
                            {cmsData["comparison"][0].widgets.map((widget: any, i: number) => (
                                <div key={i} className={styles.WidgetContainer}>
                                    <p>{widget.heading}</p>

                                    <InsightView
                                        insight={idRef(widget.widgetId)}
                                        filters={[
                                            surveyIdFilter,
                                            differentiationFilter,
                                            answerScaleFilter,
                                            ageFilter,
                                            countryFilter,
                                            genderFilter,
                                            educationFilter,
                                            householdFilter,
                                            filters,
                                        ]}
                                    />
                                </div>
                            ))}
                        </ComparisonGrid>
                    </ComparisonContainer>

                    <PieChartContainer>
                        <PieWidgetContainer>
                            <p>{cmsData["pieChart"][0].heading}</p>
                            <InsightView
                                insight={idRef(cmsData["pieChart"][0].widgetId)}
                                filters={[
                                    surveyIdFilter,
                                    differentiationFilter,
                                    answerScaleFilter,
                                    ageFilter,
                                    countryFilter,
                                    genderFilter,
                                    educationFilter,
                                    householdFilter,
                                    filters,
                                ]}
                                drillableItems={pieChartDrillableItems}
                                onDrill={onAnswerScaleDrill}
                            />
                        </PieWidgetContainer>
                    </PieChartContainer>

                    <QuestionListContainer>
                        <div>
                            <p>{cmsData["questionList"][0].heading}</p>
                            <CustomWidget
                                {...cmsData["questionList"][0]}
                                widgetId={cmsData["questionList"][0].widgetId}
                                filters={[
                                    differentiationFilter,
                                    answerScaleFilter,
                                    ageFilter,
                                    countryFilter,
                                    genderFilter,
                                    educationFilter,
                                    householdFilter,
                                    surveyIdFilter,
                                    filters,
                                ]}
                                setFilters={setFilters}
                                setAnswerScale={setAnswerScale}
                                answerScale={answerscale}
                            />
                        </div>
                    </QuestionListContainer>

                    <CustomWidgetContainer>
                        <WidgetContainerGrid>
                            <div>
                                <p>{cmsData["gender"][0].heading}</p>
                                <CustomWidget
                                    {...cmsData["gender"][0]}
                                    widgetId={cmsData["gender"][0].widgetId}
                                    filters={[
                                        differentiationFilter,
                                        answerScaleFilter,
                                        ageFilter,
                                        countryFilter,
                                        genderFilter,
                                        educationFilter,
                                        householdFilter,
                                        surveyIdFilter,
                                        filters,
                                    ]}
                                    setFilters={setFilters}
                                    setAnswerScale={setAnswerScale}
                                    answerScale={answerscale}
                                />
                            </div>
                        </WidgetContainerGrid>
                        <WidgetContainerGrid>
                            <div>
                                <p>{cmsData["income"][0].heading}</p>
                                <CustomWidget
                                    {...cmsData["income"][0]}
                                    widgetId={cmsData["income"][0].widgetId}
                                    filters={[
                                        differentiationFilter,
                                        answerScaleFilter,
                                        ageFilter,
                                        countryFilter,
                                        genderFilter,
                                        educationFilter,
                                        householdFilter,
                                        surveyIdFilter,
                                        filters,
                                    ]}
                                    setFilters={setFilters}
                                    setAnswerScale={setAnswerScale}
                                    answerScale={answerscale}
                                />
                            </div>
                        </WidgetContainerGrid>
                        <WidgetContainerGrid>
                            <div>
                                <p>{cmsData["age"][0].heading}</p>
                                <CustomWidget
                                    {...cmsData["age"][0]}
                                    widgetId={cmsData["age"][0].widgetId}
                                    filters={[
                                        differentiationFilter,
                                        answerScaleFilter,
                                        ageFilter,
                                        countryFilter,
                                        genderFilter,
                                        educationFilter,
                                        householdFilter,
                                        surveyIdFilter,
                                        filters,
                                    ]}
                                    setFilters={setFilters}
                                    setAnswerScale={setAnswerScale}
                                    answerScale={answerscale}
                                />
                            </div>
                        </WidgetContainerGrid>
                        <WidgetContainerGrid>
                            <div>
                                <p>{cmsData["education"][0].heading}</p>
                                <CustomWidget
                                    {...cmsData["education"][0]}
                                    widgetId={cmsData["education"][0].widgetId}
                                    filters={[
                                        differentiationFilter,
                                        answerScaleFilter,
                                        ageFilter,
                                        countryFilter,
                                        genderFilter,
                                        educationFilter,
                                        householdFilter,
                                        surveyIdFilter,
                                        filters,
                                    ]}
                                    setFilters={setFilters}
                                    setAnswerScale={setAnswerScale}
                                    answerScale={answerscale}
                                />
                            </div>
                        </WidgetContainerGrid>
                    </CustomWidgetContainer>
                </Container>
            ) : error ? (
                <ErrorView />
            ) : loading ? (
                <div className={styles.LoadingDiv}>
                    <CustomLoading label="...Loading" />
                </div>
            ) : (
                <ErrorView />
            )}
        </Page>
    );
};

export default DifferentiationForm;

const Container = styled.div`
    display: grid;
    gap: 16px;
    padding: 16px;
`;

const ComparisonContainer = styled.div`
    background-color: white;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
`;

const ComparisonGrid = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 16px;
`;

const PieChartContainer = styled.div`
    display: felx;
    background-color: white;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 16px;
    justify-content: center;
    align-items: center;
`;

const PieWidgetContainer = styled.div`
    width: 200px;
    height: 200px;
`;

const QuestionListContainer = styled.div`
    background-color: white;
    padding: 16px;
    grid-column-start: 1;
    grid-column-end: 4;
`;

const WidgetContainerGrid = styled.div`
    padding: 16px;
    background-color: white;
`;

const CustomWidgetContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-start: 1;
    grid-column-end: 4;
    gap: 16px;
`;
