import { createContext, FC, useState } from "react";

import { loginToFirebase, createUserToFB, signOutUser } from "../../services/FirebaseServices";

interface IFirebaseAuthContext {
    isUserSignedIn: boolean;
    createUserStatus: string;
    createUserFirebase: (username: string, password: string) => void;
    login: (username: string, password: string) => void;
    logOut: () => void;
}

export const FirebaseAuthContext = createContext<IFirebaseAuthContext | undefined>(undefined);

export const FbAuthContextProvider: FC = (props) => {
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [createUserStatus, setCreateUserStatus] = useState("");

    const createUserFirebase = async (username: string, password: string) => {
        const status = await createUserToFB(username, password);
        setCreateUserStatus(status);
    };

    const login = async (username: string, password: string) => {
        const userCridentials = await loginToFirebase(username, password);

        if (userCridentials) {
            if (userCridentials.user) {
                setIsUserSignedIn(true);
            }
        }
    };

    const logOut = async () => {
        await signOutUser();
    };

    return (
        <FirebaseAuthContext.Provider
            value={{ isUserSignedIn, createUserStatus, createUserFirebase, login, logOut }}
        >
            {props.children}
        </FirebaseAuthContext.Provider>
    );
};
