import * as React from "react";

const SvgComponent = (props) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M10 11c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2ZM4 17c.22-.72 3.31-2 6-2 0-.7.13-1.37.35-1.99C7.62 12.91 2 14.27 2 17v2h9.54c-.52-.58-.93-1.25-1.19-2H4ZM19.43 17.02c.36-.59.57-1.28.57-2.02 0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4c.74 0 1.43-.22 2.02-.57L20.59 21 22 19.59c-1.5-1.5-.79-.8-2.57-2.57ZM16 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
            fill="#FFFFFF"
        />
    </svg>
);

export default SvgComponent;
