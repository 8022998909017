import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "graphql-hooks";
import { useSelector } from "react-redux";
import { InsightView } from "@gooddata/sdk-ui-ext";
import { idRef } from "@gooddata/sdk-model";
import { newPositiveAttributeFilter, IPositiveAttributeFilter } from "@gooddata/sdk-model";

import { DEMOGRAPHIC_QUERY } from "../Querys/DemographicQuery";
import { RootState } from "../../redux/store";
//import { FilterListItems } from "./SurveyForm";
import Page from "../Page";
import { getCustomerCms } from "./query-validator";
import { IDemographicsCmsData } from "../../routes/Demographics";
import styles from "./Form.module.scss";
import CustomLoading from "../CustomLoading";
import * as Md from "../../md/full";
import ErrorView from "../ErrorView";

const DemographicsForm: React.FC = () => {
    const query = DEMOGRAPHIC_QUERY("allDemographics");
    const reduxIdFilter = useSelector((state: RootState) => state.testData.selectedSurveyFilter);
    const filtersFromRedux = useSelector((state: RootState) => state.testData.insightFiltersToApply);
    const countryFilter = useSelector((state: RootState) => state.testData.countryFilter);
    const ageFilter = useSelector((state: RootState) => state.testData.ageFilter);
    const genderFilter = useSelector((state: RootState) => state.testData.genderFilter);
    const educationFilter = useSelector((state: RootState) => state.testData.educationFilter);
    const householdFilter = useSelector((state: RootState) => state.testData.householdFilter);
    const userWorkspace = useSelector(
        (state: RootState) => state.testData.currentUserSettings?.workspaces[0],
    );
    const [cmsData, setCmsData] = useState<IDemographicsCmsData | undefined>();
    const [surveyIdFilter, setSurveyIdFilter] = useState<IPositiveAttributeFilter>(
        newPositiveAttributeFilter(Md.SurveyId.SurveyName, []),
    );
    const [filters, setFilters] = useState<IPositiveAttributeFilter>(reduxIdFilter);

    const { loading, error, data } = useQuery(query, {
        variables: {
            limit: 100,
        },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchCustomerCms = async () => {
        const customerOverview = await getCustomerCms(data.allDemographics, userWorkspace || "No-data");
        setCmsData(customerOverview);
    };

    useEffect(() => {
        if (data && userWorkspace) {
            fetchCustomerCms();
        }
    }, [data, fetchCustomerCms, userWorkspace]);

    useEffect(() => {
        if (filtersFromRedux && reduxIdFilter) {
            const newFilters = newPositiveAttributeFilter(filtersFromRedux.atrb, {
                uris: filtersFromRedux.ar,
            });
            const NewIdFilter = newPositiveAttributeFilter(reduxIdFilter.atrb, {
                uris: [reduxIdFilter.ar],
            });
            setSurveyIdFilter(NewIdFilter);
            setFilters(newFilters);
        }
    }, [filtersFromRedux, reduxIdFilter]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page showFilters={true}>
            <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                {cmsData ? (
                    <div>
                        <div className={styles.DemographicsContainer}>
                            {cmsData.demographics.map((item: any, index: number) => (
                                <Fragment key={index}>
                                    {item.heading === "Responses" ? (
                                        <div className={styles.DemographicsDescriptionContainer}>
                                            <div className={styles.DemographicsDescription}>
                                                <div className={styles.WidgetContainer}>
                                                    <p>{item.heading}</p>
                                                    <InsightView
                                                        key={item.uniqueId}
                                                        insight={idRef(item.widgetId)}
                                                        filters={[
                                                            surveyIdFilter,
                                                            countryFilter,
                                                            ageFilter,
                                                            genderFilter,
                                                            educationFilter,
                                                            householdFilter,
                                                            filters,
                                                        ]}
                                                        config={{
                                                            colors: [
                                                                "rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0)",
                                                            ],
                                                        }}
                                                    />
                                                </div>

                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <h2 style={{ color: "#000000" }}>{cmsData.title}</h2>
                                                    <p>{cmsData.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div key={index} className={styles.DemographicsWidgetContainer}>
                                                <h2 key={item.uniqueId} style={{ color: "#000000" }}>
                                                    {item.heading}
                                                </h2>

                                                <InsightView
                                                    key={item.uniqueId}
                                                    insight={idRef(item.widgetId)}
                                                    filters={[
                                                        surveyIdFilter,
                                                        countryFilter,
                                                        ageFilter,
                                                        genderFilter,
                                                        educationFilter,
                                                        householdFilter,
                                                        filters,
                                                    ]}
                                                />
                                            </div>
                                        </>
                                    )}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                ) : error ? (
                    <ErrorView />
                ) : loading ? (
                    <div className={styles.LoadingDiv}>
                        <CustomLoading label="...Loading" />
                    </div>
                ) : (
                    <ErrorView />
                )}
            </div>
        </Page>
    );
};

export default DemographicsForm;
