import { IPositiveAttributeFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../md/full";

export const getDeepDiveFilter = (attr: string): IPositiveAttributeFilter => {
    switch (attr.toLocaleLowerCase()) {
        case "image & perception": {
            return newPositiveAttributeFilter(Md.Dimension.attribute.displayForm, ["perception"]);
        }

        case "sustainability": {
            return newPositiveAttributeFilter(Md.Dimension.attribute.displayForm, ["social responsibility"]);
        }

        case "differentiation": {
            return newPositiveAttributeFilter(Md.Dimension.attribute.displayForm, ["differentiation"]);
        }

        case "segmentation": {
            return newPositiveAttributeFilter(Md.Dimension.attribute.displayForm, ["segmentation"]);
        }
    }

    return newPositiveAttributeFilter(Md.Dimension.attribute.displayForm, []);
};
