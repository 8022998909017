import React from "react";
//import WorkspacePicker from "../controls/WorkspacePicker";

import Links from "../Header/Links";
import styles from "./Sidebar.module.scss";

const Sidebar: React.FC = () => {
    return (
        <div className={styles.SidebarContent}>
            <Links />

            {/* <div className={styles.Centered}>
                <WorkspacePicker />
            </div> */}
        </div>
    );
};

export default Sidebar;
